<script setup lang="ts">
import { cloudFunctionUrl } from '@/helpers/main-utils'
import { addSimpleFeedback } from '@/plugins/firebase/firestore'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'
import axios from 'axios'
import { nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from './Button.vue'
import Modal from './Modal.vue'

const { t, locale } = useI18n()
const appStore = useAppStore()
const feedback = ref('')
const feedbackInput = ref<HTMLTextAreaElement | null>(null)

const email = ref('')

const clearInputs = () => {
  feedback.value = ''
  email.value = ''
}

watch(
  () => appStore.isFeedbackShown,
  (newVal) => {
    if (newVal) {
      nextTick(() => {
        feedbackInput.value?.focus()
      })
    } else {
      clearInputs()
    }
  },
)

const userStore = useUserStore()

const onSendFeedback = () => {
  if (!feedback.value) {
    return
  }
  const contact = userStore.authData?.email ?? email.value
  addSimpleFeedback({ message: feedback.value, contact })
  axios
    .post(cloudFunctionUrl('simpleFeedback'), {
      feedback: {
        contact,
        message: feedback.value,
        currentLocale: locale.value,
      },
    })
    .then(() => {
      console.log('Feedback sent')
    })
    .catch((error) => {
      console.error('Error sending feedback', error)
    })
  appStore.hideFeedbackModal()
  clearInputs()
  appStore.showToast('top', { emoji: 'raising-hands', text: t('FeedbackModal.thankYouToast') })
}
</script>

<template>
  <Modal ref="modal" modal-id="simpleFeedback" v-model="appStore.isFeedbackShown">
    <div class="modal">
      <div class="header">
        <img class="illustration" src="/src/assets/img/emoji/speech-balloon.png" />
        <div class="info">
          <div class="title">{{ $t('DropdownMenu.userFeedbackBtnText') }}</div>
          <p class="description" v-html="$t('DropdownMenu.userFeedbackBtnTooltip')"></p>
        </div>
      </div>

      <div class="content">
        <textarea
          @keydown.stop
          ref="feedbackInput"
          autofocus
          v-model="feedback"
          :placeholder="$t('FeedbackModal.feedbackInputPlaceholder')"
        ></textarea>

        <div v-if="!userStore.authData" class="field">
          <input v-model="email" type="text" name="email" :placeholder="$t('FeedbackModal.leaveEmailInputPlaceholder')" />
        </div>

        <div class="buttons">
          <Button size="md" variant="outlined" @click="appStore.hideFeedbackModal">{{ $t('cancel') }}</Button>
          <Button size="md" @click="onSendFeedback()" :disabled="!feedback">{{ $t('send') }}</Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.modal {
  width: 480px;
  padding: var(--s-lg);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .header {
    display: flex;
    align-items: center;
    gap: var(--s-lg);
  }

  img.illustration {
    height: 60px;
    width: 60px;
  }

  .info {
    flex: 1;
    text-align: left;

    .title {
      font-weight: 500;
      font-size: var(--fz-lg);
      margin-bottom: var(--s-xs);
    }

    .description {
      font-size: var(--fz-sm);
      color: var(--c-text-secondary);
    }
  }

  .content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: var(--s-xl);

    textarea,
    input {
      transition: border-color 200ms;
    }

    textarea {
      width: 100%;
      height: 12rem;
      padding: var(--grid-cell);
      border: 1px solid var(--c-secondary-border);
      border-radius: var(--br-lg);
      font-size: var(--fz-md);
      resize: none;
      background-color: var(--c-background);
      outline: none;
      color: var(--c-text-primary);
      font-family: var(--ff-main);

      &:hover {
        border-color: var(--c-secondary-border-darker);
      }

      &:focus-visible {
        border-color: var(--c-primary-border);
      }

      &::placeholder {
        opacity: 50%;
      }
    }

    input[type='text'] {
      margin-top: var(--s-md);
      width: 100%;
      outline: none;
      border: 1px solid var(--c-secondary-border);
      border-radius: var(--br-lg);
      background-color: var(--c-background);
      padding: var(--s-md);
      color: var(--c-text-primary);
      font-size: inherit;
      font-family: inherit;
      transition: border-color 200ms;
      &::placeholder {
        opacity: 50%;
      }
      &:hover {
        border-color: var(--c-secondary-border-darker);
      }
      &:focus-visible {
        border-color: var(--c-primary-border);
      }
    }

    .buttons {
      margin-top: auto;
      padding-top: var(--s-md);
      display: flex;
      justify-content: flex-end;
      gap: var(--s-md);

      @media (max-width: 768px) {
        flex-direction: column;

        .btn {
          flex: unset;
        }
      }
    }
  }
}
</style>
