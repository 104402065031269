<script setup lang="ts">
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import { useAppStore } from '@/stores/appStore'
import { RouterView, useRoute } from 'vue-router'
import CookieModal from './components/CookieModal.vue'
import CursorToast from './components/CursorToast.vue'
import FeedbackModal from './components/FeedbackModal.vue'
import LoadingView from './components/LoadingView.vue'
import PaymentSelectionModal from './components/PaymentSelectionModal.vue'
import TheSvgSprite from './components/TheSvgSprite.vue'
import Toast from './components/Toast.vue'
import { useExitIntent } from './helpers/useExitIntent'
import { useCookieStore } from './stores/cookieStore'
import { useUserStore } from './stores/userStore'

const route = useRoute()
const appStore = useAppStore()

const userStore = useUserStore()
const cookieStore = useCookieStore()
useExitIntent()
</script>

<template>
  <TheSvgSprite />
  <CookieModal v-if="!cookieStore.areCookieSettingsResolved" />
  <FeedbackModal />
  <PaymentSelectionModal />

  <!-- Toasts -->
  <Toast :show="appStore.toastData !== null" :text="appStore.toastData?.text ?? ''" :emoji="appStore.toastData?.emoji ?? ''" />
  <CursorToast
    :show="appStore.cursorToastData !== null"
    :text="appStore.cursorToastData?.text ?? ''"
    :emoji="appStore.cursorToastData?.emoji ?? ''"
  />

  <!-- landing -->
  <RouterView v-if="route.name === 'landing'" />
  <!-- app -->
  <div v-else v-show="userStore.initialized" id="ts-app">
    <div id="app-header">
      <AppHeader v-if="!route.meta.customHeader" />
    </div>

    <div id="app-content">
      <div class="current-view">
        <RouterView />
      </div>
    </div>

    <div id="app-footer">
      <AppFooter v-if="!route.meta.customFooter" />
    </div>
  </div>
  <!-- loader -->
  <LoadingView v-if="appStore.loaderData" :warn-before-close="appStore.loaderData.warnBeforeClose" :text="appStore.loaderData.text" />
</template>

<style>
@import '@flaticon/flaticon-uicons/css/all/all';
@import 'assets/fonts/rf-dewi/stylesheet';
</style>

<style lang="scss">
:root {
  box-sizing: border-box;
  // font-size: 87.5%;

  // @media (min-width: 1200px) {
  //   font-size: 100%;
  // }

  // @media (min-width: 1440px) {
  //   font-size: 112.5%;
  // }
}

*,
:before,
:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: var(--c-background);
  color: var(--c-text-primary);
  line-height: 1.4;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
}

a {
  text-decoration: none;
  color: var(--c-primary);

  &:hover {
    color: var(--c-primary-hover);
  }

  &:active {
    color: var(--c-primary-active);
  }
}

// Pages transition

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-out;
  // transition: opacity .2s ease-out;
}

// App grid

#ts-app {
  --vh-100: calc(var(--vh, 1vh) * 100);

  min-height: var(--vh-100);
  display: grid;
  grid:
    'header' var(--header-height)
    'messages' min-content
    'content' auto
    'footer' minmax(var(--grid-cell), min-content);
  min-width: var(--min-viewport-width);

  #app-content {
    min-width: var(--min-viewport-width);
    grid-area: content;
    padding: 0 var(--grid-cell);
    width: 100%;
    max-width: 100vw;
    display: flex;

    .current-view {
      min-height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  #app-header {
    grid-area: header;
    z-index: 12;
  }

  #messages {
    z-index: 5;
  }

  #app-footer {
    grid-area: footer;
  }

  #app-header nav,
  #app-footer footer,
  #messages .message-bar {
    padding-left: var(--grid-cell);
    padding-right: var(--grid-cell);
  }
}

// Typography

// .title {
//   font-size: 3rem;
// }

// .headline-1 {
//   color: var(--c-text-primary);
//   font-weight: 700;
//   font-size: 2.5rem;
//   padding: 0;
//   margin: 0.5em 0;
//   line-height: 1.25;
// }

// .subtitle {
//   color: var(--c-text-secondary);
//   font-weight: 400;
//   font-size: 1.25rem;
// }

// .paragraph {
//   color: var(--c-text-primary);
//   font-weight: 400;
//   font-size: 1.125rem;
//   padding: 0;
//   margin: 0.5em 0;

//   &.secondary {
//     color: var(--c-text-secondary);
//   }
// }

b {
  font-weight: 600;
}

// Tooltip

.tippy-box {
  --c-tooltip-bg: var(--c-primary);
  --c-tooltip-border: var(--c-primary);
  --c-tooltip-text: var(--c-text-on-primary);
}

.tippy-box[data-theme~='danger'] {
  --c-tooltip-bg: var(--c-text-danger);
  --c-tooltip-border: var(--c-text-danger);
}

.tippy-box[data-theme~='info'] {
  --c-tooltip-bg: var(--c-surface);
  --c-tooltip-border: var(--c-surface);
  --c-tooltip-text: var(--c-text-primary);
}

.tippy-box[data-theme~='ts'] {
  background-color: var(--c-tooltip-bg);
  border: 1px solid var(--c-tooltip-border);
  border-radius: var(--br-xl);
  padding: calc(var(--s-xs) * 1.5);
  box-shadow:
    0px 4px 32px 0px rgba(0, 0, 0, 0.16),
    0px 0px 4px 0px rgba(0, 0, 0, 0.04);

  .tippy-content {
    font-weight: 500;
    font-size: var(--fz-sm);
    color: var(--c-tooltip-text);
  }
}

.tippy-box[data-theme~='ts'] > .tippy-svg-arrow {
  fill: var(--c-tooltip-bg);
  color: var(--c-tooltip-bg);
}
.tippy-box[data-theme~='ts'][data-placement='top'] > .tippy-svg-arrow > svg {
  transform: rotate(180deg) !important;
  left: 50%;
  translate: -50% 0;
  top: 15.4px !important;
}
.tippy-box[data-theme~='ts'][data-placement='bottom'] > .tippy-svg-arrow > svg {
  transform: none !important;
  left: 50%;
  translate: -50% 0;
  bottom: 15.4px !important;
}
.tippy-box[data-theme~='ts'][data-placement='right'] .tippy-svg-arrow > svg {
  transform: rotate(270deg) !important;
  top: 50%;
  translate: 0 -50%;
  right: 10.7px !important;
}
.tippy-box[data-theme~='ts'][data-placement='left'] .tippy-svg-arrow > svg {
  transform: rotate(90deg) !important;
  top: 50%;
  translate: 0 -50%;
  left: 10.7px !important;
}

// components
// REFACTOR: move to components.scss

.ts-toggle {
  border: 1px solid var(--c-divider);
  border-radius: var(--br-sm);
  padding: 0;
  display: flex;
  overflow: hidden;
  //width: 460px; // hardcoded to max width to prevent changing width on tab change because of font weight change
  display: flex;
  height: var(--height-btn-sm);

  button {
    flex: auto;
    font-size: var(--fz-sm);
    // font-weight: 500;
    padding: 0 1em;
    background: none;
    border: none;
    color: var(--c-text-primary);
    margin: 0;
    cursor: pointer;
    outline: none;
    font-weight: 500;

    &:nth-child(2n) {
      border-left: 1px solid var(--c-divider);
      border-right: 1px solid var(--c-divider);
    }

    &:last-child {
      border-right: none;
    }

    &.active {
      color: var(--c-text-primary);
      background-color: var(--c-surface);
      // font-weight: 500;
      color: var(--c-text-primary);
      // letter-spacing: -0.24px;
    }
  }

  &.sm {
    height: 2rem;
    // border-radius: var(--br-md);

    button {
      padding: 0 0.8em;
      font-size: var(--fz-xs);
    }
  }
}

.ts-chip {
  display: flex;
  align-items: center;
  border-radius: var(--br-md);
  background-color: var(--c-secondary);
  padding: 6px 6px 6px 10px;
  margin-right: var(--s-sm);
  font-size: var(--fz-sm);
  cursor: pointer;
  transition: background-color 0.2s;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: var(--c-secondary-hover);
  }

  & * {
    user-select: none;
  }

  &.active {
    background-color: var(--c-primary);
    color: var(--c-text-on-primary);

    &:hover {
      background-color: var(--c-primary-hover);
    }
  }

  .label {
    margin-right: var(--s-xs);
    font-weight: 500;
  }

  .shortcut {
    opacity: 0.9;
  }

  &.active {
    .shortcut {
      background: var(--c-text-on-primary);
      opacity: 0.7;
      color: #1f1f1f;
    }
  }
}

.keyboard-shortcuts {
  display: flex;

  &.bottom-left {
    position: fixed;
    left: var(--grid-cell);
    bottom: var(--grid-cell);
  }

  .combo {
    display: flex;
    gap: 0.2rem;
  }

  .shortcuts {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 0.5rem;
  }

  .shortcut {
    font-size: var(--fz-sm);
    font-weight: normal;
  }

  .labels {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.5rem;
    margin-left: 0.5rem;
    font-size: var(--fz-sm);
    color: var(--c-text-secondary);

    .label {
      &.highlight {
        font-weight: 500;
        color: var(--c-text-primary);
      }
    }
  }
}

.text-secondary {
  color: var(--c-text-secondary);
}

.shortcut-hint {
  font-size: var(--fz-xs);
  color: var(--c-text-secondary);
}

.text-sm {
  font-size: var(--fz-sm) !important;
}
.text-xs {
  font-size: var(--fz-xs) !important;
}

.fw-400 {
  font-weight: normal !important;
}

.kinda-mono {
  font-family: var(--ff-main);
  font-feature-settings:
    'tnum' on,
    'zero' on;
}
</style>
