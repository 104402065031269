<script setup lang="ts">
import Keyboard from '@/components/Keyboard.vue'
import { Chapter, TrainerView } from '@/course/course-types'
import { KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { Char, KeyChar } from '@/helpers/keyboards/KeyChar'
import type { TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { KeyPressHelper } from '@/helpers/press-helper'
import { LimitType, Trainer, typingTextRowLength, type FullTypingResult } from '@/helpers/Trainer'
import type { UserLocale } from '@/plugins/i18n'
import { useElementVisibility } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { previewLayoutDefinition } from './highlights-data'

const { t, locale } = useI18n()

const userLayout = ref(KeyboardLayout.fromLayoutDefinition(previewLayoutDefinition(locale.value as UserLocale)))
const trainer = ref<Trainer>(new Trainer(userLayout.value))
let keyPressHelper = new KeyPressHelper(userLayout.value)

let text = computed(() => t('Landing.textToType'))

watch(locale, (to) => {
  const layout = KeyboardLayout.fromLayoutDefinition(previewLayoutDefinition(to as UserLocale))
  userLayout.value = layout

  keyPressHelper.reset()
  keyPressHelper = new KeyPressHelper(layout)

  trainer.value.clearInterval()
  trainer.value = new Trainer(layout)
  trainer.value.init({
    view: TrainerView.ThreeLines,
    text: text.value,
    limitType: LimitType.Unlimited,
    limit: 0,
    lessonCoords: { chapter: Chapter.HomeRow, index: 1 },
    onFinish: (result: FullTypingResult) => {
      trainer.value.restart()
    },
  })
})

const finished = ref(false)

trainer.value.init({
  view: TrainerView.ThreeLines,
  text: text.value,
  limitType: LimitType.Unlimited,
  limit: 0,
  lessonCoords: { chapter: Chapter.HomeRow, index: 1 },
  onFinish: (result: FullTypingResult) => {
    trainer.value.restart()
  },
})

const firstWrongIndex = ref(-1)

// lifecycle

const intervalId = ref<ReturnType<typeof setInterval> | undefined>(undefined)
const intervalFn = () => {
  let keyToPress = trainer.value.keyToPress()

  if (!keyToPress) {
    return
  }

  const charValue = userLayout.value.getChar(keyToPress.keyCode as TypeableKeyCode, keyToPress.layer)

  const keyChar = userLayout.value.getSymbol(keyToPress.keyCode as TypeableKeyCode, keyToPress.layer) ?? KeyChar.Space()
  const print = keyChar.isDead() ? [] : [charValue ? new Char(charValue) : new Char(' ')]

  const press = keyPressHelper.produceStandartizedKeyPress(
    { key: keyChar.isDead() ? 'Dead' : keyChar.value, code: keyToPress.keyCode } as KeyboardEvent,
    keyToPress.layer,
  )
  trainer.value.registerPress(press)
}

const keyboardRef = ref(null)
const visible = useElementVisibility(keyboardRef)

watch(
  visible,
  (to) => {
    clearInterval(intervalId.value)
    intervalId.value = undefined

    if (to) {
      trainer.value.resume()
      intervalId.value = setInterval(intervalFn, 300)
    } else {
      trainer.value.restart()
      trainer.value.pause()
    }
  },
  { immediate: true },
)
</script>

<template>
  <section class="trainer" :class="{ paused: trainer.isPaused() }">
    <div class="text-wrapper smooth-opacity-transition" :style="{ opacity: finished ? 0 : 1 }">
      <div v-for="(row, i) in trainer.getTextState(3)" :key="i" class="row">
        <div
          v-for="(charState, j) in row"
          :key="charState.index"
          class="cell"
          :class="{
            correct: charState.typed && charState.typed.isEqual(charState.toType),
            wrong: charState.typed && firstWrongIndex !== -1 && firstWrongIndex <= charState.index,
            cursor: trainer.cursorIndex === charState.index && !trainer.isPaused(),
            'last-typed': trainer.cursorIndex - 1 === charState.index,
            'last-in-row': row.length - 1 === j,
            'typed-dead': charState.typedDead,
          }"
          :style="{ flex: `0 0 ${100 / typingTextRowLength[TrainerView.ThreeLines]}%` }"
        >
          <div class="inner">
            {{ charState.typed?.value || charState.toType.value }}
          </div>
        </div>
      </div>
    </div>

    <div class="keyboard-wrapper smooth-opacity-transition">
      <Keyboard
        ref="keyboardRef"
        :layout="userLayout"
        :keyToPress="trainer.keyToPress()"
        :handle-presses="false"
        :showFingerMapping="true"
        :show-hands="true"
        :show-keyboard="true"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.trainer {
  flex: 1;
  display: grid;
  grid:
    'text' min-content
    '.' minmax(calc(var(--spacing-between-keyboard, 3rem) - 0.5rem), 1fr)
    'keyboard' min-content
    / 45rem;
  justify-content: center;
  position: relative;

  .trainer-size-view {
    display: grid;
    place-content: center center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }

  .text-wrapper {
    grid-area: text;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    position: relative;

    .row {
      flex: 1;
      display: flex;
      justify-content: center;

      .cell {
        color: var(--c-text-tertiary);

        display: flex;
        flex-direction: column;
        padding: 0.2em 0;

        .inner {
          height: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 1em;
          font-family: var(--ff-mono);
          font-size: var(--fz-heading-2);
        }

        &.correct {
          .inner {
            color: var(--c-text-primary);
          }
        }

        &.wrong {
          background-color: var(--c-danger-bg);
          .inner {
            color: var(--c-danger-text);
          }
        }

        &.typed-dead {
          .inner {
            color: var(--c-primary);
          }
        }

        &.cursor {
          position: relative;

          &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: calc(100% - 2px);
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 10px;
            background: var(--c-primary);
          }
        }
      }
    }
  }

  .keyboard-wrapper {
    grid-area: keyboard;
    position: relative;
    width: 120%;
    margin-left: -10%;
  }
}

.padding-placeholder {
  height: var(--header-height);
}
</style>
