<script setup lang="ts">
import { logAnalyticsEvent } from '@/helpers/analytics'
import { cloudFunctionUrl } from '@/helpers/main-utils'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'
import axios from 'axios'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Modal from './Modal.vue'

const { t, locale } = useI18n()
const appStore = useAppStore()

const priceInRub = ref<null | number>(null)

axios.get(cloudFunctionUrl('USD_RUB')).then((response) => {
  if (response.data.value) {
    priceInRub.value = Number(response.data.value) * 9
  }
})

const userStore = useUserStore()

const payViaTelegram = async () => {
  if (!userStore.authData) {
    return
  }
  appStore.showLoader('startPaymentRequest', 'Создаём транзакцию...')
  appStore.isPaymentSelectionShown = false
  logAnalyticsEvent('checkout_start', { method: 'telegram' }, { send_immediately: true })
  const response = await axios.post(cloudFunctionUrl('checkout'), { email: userStore.authData.email, provider: 'telegram' })
  appStore.hideLoader('startPaymentRequest')
  window.location.assign(response.data)
}

const payViaStripe = async () => {
  if (!userStore.authData) {
    return
  }
  appStore.showLoader('startPaymentRequest', t('Home.talkingToStripe'))
  appStore.isPaymentSelectionShown = false
  logAnalyticsEvent('checkout_start', { method: 'stripe' }, { send_immediately: true })
  const response = await axios.post(cloudFunctionUrl('checkout'), { email: userStore.authData.email, provider: 'stripe' })
  appStore.hideLoader('startPaymentRequest')
  window.location.assign(response.data)
}
</script>

<template>
  <Modal ref="modal" modal-id="simpleFeedback" v-model="appStore.isPaymentSelectionShown">
    <div class="modal">
      <div class="header">
        <div class="title">Выберите удобный метод оплаты</div>
      </div>

      <div class="content">
        <div class="option" @click="payViaTelegram">
          <div class="title">Карты РФ / РБ</div>
          <img class="telegram" src="/src/assets/img/telegram-logo.svg" alt="" />
          <div class="price">
            <div class="native">
              <img src="/src/assets/img/telegram-star.svg" alt="" />
              500
            </div>
            <div class="rub">≈990 ₽</div>
          </div>
        </div>
        <div class="option" @click="payViaStripe">
          <div class="title">Иностранные карты</div>
          <img class="stripe" src="/src/assets/img/stripe-logo.svg" alt="" />
          <div class="price">
            <div class="native">$9</div>
            <div class="rub">≈{{ priceInRub ? priceInRub.toFixed(0) : '...' }} ₽</div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.modal {
  width: 480px;
  padding: var(--s-lg);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    font-weight: 500;
    font-size: var(--fz-lg);
  }

  .content {
    width: 100%;
    flex: 1;
    display: flex;
    margin-top: var(--s-xxl);
    gap: var(--s-lg);

    .option {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--c-divider);
      border-radius: var(--br-lg);
      padding: var(--s-md);
      flex: 1;
      height: 160px;
      justify-content: space-between;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        background: var(--c-secondary-hover);
      }

      .title {
        font-size: var(--fz-sm);
        font-weight: 400;
      }

      img {
        height: 36px;
        &.telegram {
          height: 48px;
        }
      }

      .price {
        display: flex;
        gap: var(--s-md);
        justify-content: center;
        align-items: center;

        .native {
          display: flex;
          align-items: center;
          gap: var(--s-xs);

          img {
            width: 18px;
            height: 18px;
            position: relative;
            bottom: 1px;
          }
        }

        .rub {
          color: var(--c-text-secondary);
        }
      }
    }
  }
}
</style>
