<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed } from 'vue'

// Define a type for your props
type Props = {
  variant?: 'primary' | 'outlined'
  size?: 'sm' | 'md' | 'lg'
  state?: '' | 'selected' | 'on-selected-block'
  disabled?: boolean
  loading?: boolean
  iconPath?: string // Optional with "?" and no default means it's not required
  link?: string // Optional
  shortcut?: string
  prependIcon?: string
}

// Use defineProps with generic to specify the props type
const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  size: 'sm',
  state: '',
  disabled: false,
})

const handleClick = () => {
  if (!props.link) {
    return true
  }

  window.location.href = props.link
  return false
}

const imgSrc = computed(() => {
  return new URL(`/src/assets/img/${props.iconPath}`, import.meta.url).href
})
</script>

<template>
  <button class="btn" :class="[variant, props.size, state, loading ? 'loading' : '']" :disabled="disabled || loading" @click="handleClick">
    <img v-if="iconPath" :src="imgSrc" class="button-icon" />
    <Icon v-if="prependIcon" :icon="prependIcon" class="icon prepend" />
    <div class="content-wrapper">
      <slot />
      <div v-if="shortcut" class="shortcut">
        <i v-if="shortcut === 'Enter'" class="fi fi-br-arrow-turn-down-left"></i>
        <template v-else>{{ shortcut }}</template>
      </div>
    </div>
    <div class="loader">
      <svg width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="4">
            <circle stroke-opacity=".5" cx="20" cy="20" r="18" />
            <path d="M38 20c0-9.94-8.06-18-18-18">
              <animateTransform attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="1s" repeatCount="indefinite" />
            </path>
          </g>
        </g>
      </svg>
    </div>
  </button>
</template>

<style lang="scss" scoped>
@mixin colorize($bg, $border, $text, $bg-hover, $bg-active) {
  --bg-hover: var(--c-#{$bg-hover});
  background: var(--c-#{$bg});
  border-color: var(--c-#{$border});
  color: var(--c-#{$text});

  &:not(:disabled):hover {
    background: var(--c-#{$bg-hover});
  }

  &:not(:disabled):active {
    background: var(--c-#{$bg-active});
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1em;
  font-weight: 500;
  outline: none;
  transition: all 0.2s ease-out;
  border-width: 1px;
  border-style: solid;
  position: relative;

  &.sm {
    font-size: var(--fz-sm);
    border-radius: var(--br-md);
    padding: 0 1em;
    height: var(--height-btn-sm);
  }

  &.md {
    font-size: var(--fz-md);
    border-radius: var(--br-md);
  }

  &.lg {
    font-size: var(--fz-lg);
    border-radius: var(--br-md);
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.primary {
    @include colorize(primary, primary-border, text-on-primary, primary-hover, primary-active);
  }

  // &.secondary {
  //   @include colorize(secondary, secondary-border, text-primary, secondary-hover, secondary-active);
  // }

  &.outlined {
    // background: none;
    @include colorize(null, secondary-border, secondary-text, secondary-hover, secondary-active);
  }

  // Icon

  .button-icon {
    height: 1em;
    margin-right: 0.375em;
  }

  .icon {
    width: 1em;
    height: 1em;

    &.prepend {
      margin-right: 0.375em;
    }
    &.append {
      margin-left: 0.375em;
    }
  }

  .content-wrapper {
    display: flex;
    align-items: center;

    .shortcut {
      background-color: var(--bg-hover);
      display: inline-flex;
      align-items: center;
      padding: 2px 6px;
      margin-left: 6px;
      border-radius: var(--br-sm);
      font-size: var(--fz-sm);

      :deep(i.fi:before) {
        font-size: var(--fz-xs);
      }
    }
  }

  .loader {
    display: none;
  }
}

.loading {
  .content-wrapper {
    opacity: 0;
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}
</style>
