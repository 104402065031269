<script lang="ts" setup>
import { KeyboardFormat, KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import type { LanguageCode } from '@/languages/languages-config'
import { getLayoutsMetadata } from '@/layouts/layouts-config'
import { getLangTitle } from '@/plugins/i18n'
import { LayoutDefinition } from '@/types/LayoutDefinition'
import type { OS } from '@/types/main-types'
import { onClickOutside } from '@vueuse/core'
import { computed, useTemplateRef } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from './Button.vue'

type Props = {
  os: OS
}
const props = defineProps<Props>()

const { t, locale } = useI18n()

const value = defineModel<string | null>('selected', { default: null })

const buildLayouts = () => {
  const layouts = Object.keys(getLayoutsMetadata(props.os, locale.value)).map((layoutId) => {
    const l = KeyboardLayout.fromLayoutDefinition(new LayoutDefinition(props.os, KeyboardFormat.ANSI, layoutId, 'en'))
    l.languageCode = l.primaryLanguage
    return l
  })
  return layouts
}

const allLayoutsList = computed(() => buildLayouts())

const allLayoutsGrouped = computed(() => {
  let result: Partial<Record<LanguageCode, { id: string; title: string }[]>> = {}

  for (const layout of allLayoutsList.value) {
    const layoutInfo = { id: layout.layoutId, title: layout.title }
    if (result[layout.languageCode]) {
      result[layout.languageCode]!.push(layoutInfo)
    } else {
      result[layout.languageCode] = [layoutInfo]
    }
  }

  return result
})

// main dropdown

const dropdownRef = useTemplateRef('dropdown')

const isDropdownOpened = defineModel<boolean>({ default: false })

onClickOutside(dropdownRef, () => {
  isDropdownOpened.value = false
})

const menuButtonText = computed(() => {
  if (value.value) {
    return allLayoutsList.value.find((l) => l.layoutId === value.value)!.title
  }
  return t('Onboarding.selectManually')
})

const onLayoutClick = (layoutId: string) => {
  value.value = layoutId
  isDropdownOpened.value = false
}
</script>

<template>
  <div class="dropdown" ref="dropdown">
    <Button variant="outlined" size="md" @click="isDropdownOpened = !isDropdownOpened">
      <span class="text">{{ menuButtonText }}</span>
      <svg class="icon arrow-down" :class="{ up: isDropdownOpened }">
        <use href="#icon-arrow"></use>
      </svg>
    </Button>
    <transition name="dropdown-dialog">
      <ul v-show="isDropdownOpened" class="dialog">
        <template v-for="(layouts, lang) in allLayoutsGrouped" :key="lang">
          <li class="dialog-item title">{{ getLangTitle(lang) }}</li>
          <li
            v-for="layout in layouts"
            :key="layout.id"
            :value="layout.id"
            @click="onLayoutClick(layout.id)"
            class="dialog-item"
            :class="{ active: value === layout.id }"
          >
            <div class="text">{{ layout.title }}</div>
          </li>
        </template>
      </ul>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10;

  .arrow-down {
    margin-left: var(--s-sm);
    position: relative;
    transition: all 0.2s ease;
    color: var(--c-secondary-icon);
    width: 0.875rem;
    height: 0.875rem;
    &.up {
      transform: rotate(180deg);
    }
  }

  .dialog {
    --vertical-spacing: 6px;
    --arrow-right-width: calc(0.75rem + 2 * var(--s-md));
    --sm-icon-size: 0.75rem;

    list-style: none;
    margin: 0;
    padding: var(--vertical-spacing) 0;
    background-color: var(--c-background);
    border: 1px solid var(--c-secondary-border);
    border-radius: var(--br-md);
    position: absolute;
    left: 0;
    top: calc(100% + 0.25rem);
    width: auto;
    white-space: nowrap;
    box-shadow: var(--box-shadow-default);
    font-weight: normal;
    max-height: 310px;
    overflow-y: auto;

    &.nested {
      top: calc(-1px - var(--vertical-spacing));
      right: calc(100% + 0.25rem);

      .dialog-item {
        // equal empty space for left and right sides
        padding-right: calc(var(--s-md) + var(--sm-icon-size) + var(--gap));

        .text {
          margin-right: 0;
        }
      }
    }

    .dialog-item {
      --gap: var(--s-sm);
      position: relative;
      display: flex;
      gap: var(--gap);
      align-items: center;
      padding: var(--vertical-spacing) var(--s-md);
      color: var(--c-text-primary);
      font-size: var(--fz-sm);
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      &.danger {
        color: var(--c-text-danger);
      }

      &:hover,
      &.open,
      &.active {
        background-color: var(--c-secondary-hover);
      }

      .text {
        margin-right: var(--s-md);

        .description {
          // margin-right: calc(-1 * calc(var(--s-md) + var(--sm-icon-size) + var(--gap)));
          font-size: var(--fz-xs);
          color: var(--c-text-secondary);
          font-weight: normal;

          &.wide {
            width: 200px;
            white-space: wrap;
          }
        }
      }

      .toggle {
        margin-left: auto;
      }

      .icon {
        &.prepend {
          width: 1rem;
          height: 1rem;
        }

        &.check {
          width: var(--sm-icon-size);
          height: var(--sm-icon-size);
          visibility: hidden;
        }

        &.arrow-right {
          width: var(--sm-icon-size);
          height: var(--sm-icon-size);
          margin-left: auto;
          color: var(--c-secondary-icon);
          rotate: 270deg;
        }
      }
      &.active .icon.check {
        visibility: visible;
      }

      &.title {
        padding: var(--vertical-spacing) var(--s-md);
        padding-top: var(--s-md);
        color: var(--c-text-tertiary);
        font-size: var(--fz-xs);
        &:hover {
          background: none;
          cursor: default !important;
        }
      }
    }

    .divider {
      width: 100%;
      height: var(--vertical-spacing);
    }
  }
}

.dropdown-dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dropdown-dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dropdown-dialog-enter-from,
.dropdown-dialog-leave-to {
  transform: translateY(-0.625rem);
  transform: scale(0.8);
  opacity: 0;
}
</style>
