<script setup lang="ts">
import { useUserStore } from '@/stores/userStore'
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from './Button.vue'

const { t } = useI18n()
const userStore = useUserStore()

const model = defineModel<boolean>()
const modalInputRef = useTemplateRef('modalInput')

const inputValue = ref('')
const isInputValueValid = computed(() => !!inputValue.value.trim())

const initValue = computed(() => userStore.name)

watch(model, (to) => {
  // update with init value on enter/exit
  inputValue.value = initValue.value

  // focus input on enter
  if (to) {
    nextTick(() => {
      modalInputRef.value?.focus()
    })
  }
})

// actions

const submit = () => {
  if (!isInputValueValid.value) {
    return
  }
  userStore.saveData({ name: inputValue.value })
  model.value = false
}

const discard = () => {
  inputValue.value = initValue.value
  model.value = false
}
</script>

<template>
  <Teleport to="body">
    <div v-if="model" class="overlay">
      <div class="modal" ref="modal">
        <img class="illustration" src="/src/assets/img/id-card.png" alt="ID Card" />

        <div class="content">
          <p class="title">{{ t('editName') }}</p>
          <div class="text">
            <div class="field">
              <input @keydown.stop v-model="inputValue" ref="modalInput" type="text" :placeholder="initValue" />
            </div>
          </div>
          <div class="buttons">
            <Button variant="outlined" @click="discard">{{ t('cancel') }}</Button>
            <Button type="primary" @click="submit" :disabled="!isInputValueValid">{{ t('save') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--c-modal-backdrop);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: var(--c-surface);
  border-radius: var(--br-xl);
  border: 1px solid var(--c-secondary-border);
  padding: var(--s-lg);
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img.illustration {
    height: 80px;
    width: auto;
  }

  .content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: var(--s-lg);

    .title {
      font-weight: 700;
      font-size: var(--fz-lg);
      margin-bottom: var(--s-sm);
    }

    .text {
      font-size: var(--fz-md);
    }

    .field {
      input[type='text'] {
        width: 100%;
        outline: none;
        border: 1px solid var(--c-secondary-border);
        border-radius: var(--br-lg);
        background-color: var(--c-background);
        padding: var(--s-md);
        color: var(--c-text-primary);
        font-size: inherit;
        font-family: inherit;
        transition: border-color 200ms;
        &::placeholder {
          opacity: 50%;
        }
        &:hover {
          border-color: var(--c-secondary-border-darker);
        }
        &:focus-visible {
          border-color: var(--c-primary-border);
        }
      }
    }

    .buttons {
      margin-top: auto;
      padding-top: var(--s-xl);
      display: flex;
      gap: var(--s-md);

      .btn {
        flex: 1;
        min-width: unset;
      }

      @media (max-width: 768px) {
        flex-direction: column;

        .btn {
          flex: unset;
        }
      }
    }
  }
}
</style>
