// useExitIntent.ts
import { useCourseStore } from '@/stores/courseStore'
import { OS } from '@/types/main-types'
import { useLocalStorage, useMouse, useWindowSize } from '@vueuse/core'
import { computed, onBeforeUnmount, watchEffect } from 'vue'
import { logAnalyticsEvent } from './analytics'
import { getUserOS } from './user-agent-utils'

export function useExitIntent() {
  // relevant mouse position

  const leftThresholdPx = 160
  const rightThresholdPx = 200
  const topThreshold = 3

  const exitIntentCatched = useLocalStorage('testExitIntentCatched', false)

  const { width } = useWindowSize()
  const { x, y, sourceType } = useMouse()

  const userOS = getUserOS()

  // relevant user

  const courseStore = useCourseStore()

  const statsPerDay = computed(() => Object.values(courseStore.current.stats.calendar.value))

  const isRecentUser = computed(() => {
    return statsPerDay.value.length === 1 && statsPerDay.value[0].trainingCount >= 1 && statsPerDay.value[0].trainingCount <= 2
  })

  const stopWatch = watchEffect(() => {
    if (exitIntentCatched.value || statsPerDay.value.length > 1 || !isRecentUser.value) {
      return
    }

    if (
      sourceType.value === 'mouse' &&
      y.value <= topThreshold &&
      x.value >= leftThresholdPx &&
      x.value <= width.value - rightThresholdPx &&
      (userOS === OS.win || (userOS === OS.mac && x.value < width.value / 2))
    ) {
      exitIntentCatched.value = true
      logAnalyticsEvent('exit_intent_catch_test', {}, { send_immediately: true })
    }
  })

  onBeforeUnmount(() => {
    stopWatch()
  })

  return {}
}
