import { typeableKeyCodes, type KeyCode, type ModifierKeyCode, type TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { KeyboardKey } from '@/helpers/keyboards/KeyboardKey'
import { allLangConfig } from '@/languages/all-lang-config'
import { type LanguageCode } from '@/languages/languages-config'
import { getByKeyboardFormat, parseTxtLayoutConfig, type LayoutConfig } from '@/layouts/layout-config-parser'
import { getLayoutsMetadata, layoutsConfig } from '@/layouts/layouts-config'
import { i18n } from '@/plugins/i18n'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import type { LayoutDefinition } from '@/types/LayoutDefinition'
import { OS } from '@/types/main-types'
import { getUserOS } from '../user-agent-utils'
import { Char, type KeyChar } from './KeyChar'
import { Layer, allLayersByPriority } from './Layer'

export enum KeyboardFormat {
  ISO = 'iso',
  ANSI = 'ans',
  Unknown = 'unknown', // placeholder for keyboard setup
  // add later: JIS, ABNT, Korean
  // ABNT, Korean — even Apple doesn't support, should we?...
  // https://www.w3.org/TR/uievents-code/
  // https://en.wikipedia.org/wiki/List_of_QWERTY_keyboard_language_variants
}

export type LayoutMap = Map<TypeableKeyCode, KeyboardKey>

export type PressObject = {
  code: KeyCode // consistent IntlBackslash on Mac
  key: string // raw, could be 'Dead'
  prevDead: boolean

  // layout defined and typeable chars only
  layeredCode?: LayeredKeyCode // undefined, if not typeable
  symbol?: KeyChar // internal, includes all info
}

export class KeyboardLayout {
  os: OS
  layoutId: string
  languageCode: LanguageCode
  format: KeyboardFormat
  keymap: LayoutMap
  shortcutKeymap: LayoutMap
  _supportsOptionLayer?: boolean

  constructor(os: OS, layoutId: string, format: KeyboardFormat, languageCode: LanguageCode, keymap: LayoutMap, shortcutKeysMap?: LayoutMap) {
    const keymapCopy = keymap
    if (format === KeyboardFormat.ANSI && keymapCopy.has('IntlBackslash')) {
      keymapCopy.delete('IntlBackslash')
    }
    this.keymap = keymapCopy

    this.os = os
    this.layoutId = layoutId
    this.languageCode = languageCode
    this.format = format
    this.shortcutKeymap = shortcutKeysMap ?? keymapCopy
  }

  // initDerived() {
  //   this.title = getLayoutsMetadata(this.definition.os)[this.definition.layoutId]?.title
  //   this.languageTitle = getLanguagesMetadata()[this.definition.languageCode]?.title
  // }

  // get format() {
  //   return this.definition.format
  // }
  // get os() {
  //   return this.definition.os
  // }
  // get layoutId() {
  //   return this.definition.layoutId
  // }
  // get languageCode() {
  //   return this.definition.languageCode
  // }

  get keyRows() {
    const rows: TypeableKeyCode[][] = [
      ['Backquote', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9', 'Digit0', 'Minus', 'Equal'],
      ['KeyQ', 'KeyW', 'KeyE', 'KeyR', 'KeyT', 'KeyY', 'KeyU', 'KeyI', 'KeyO', 'KeyP', 'BracketLeft', 'BracketRight'],
      ['KeyA', 'KeyS', 'KeyD', 'KeyF', 'KeyG', 'KeyH', 'KeyJ', 'KeyK', 'KeyL', 'Semicolon', 'Quote'],
      ['KeyZ', 'KeyX', 'KeyC', 'KeyV', 'KeyB', 'KeyN', 'KeyM', 'Comma', 'Period', 'Slash'],
    ]

    if (this.format === KeyboardFormat.ISO) {
      rows[2].push('Backslash')
      rows[3].unshift('IntlBackslash')
    } else {
      rows[1].push('Backslash')
    }

    return rows.map((row) => row.map((keyCode) => this.keymap.get(keyCode) ?? new KeyboardKey(keyCode)))
  }

  allKeys(withSpace = false) {
    const result = [...this.keyRows.flat()]
    if (withSpace) {
      result.push(KeyboardKey.Space())
    }
    return result
  }

  allChars(withSpace = false) {
    // for now just filter out all dead keys
    // but in future we may need to use dead key id to identify ALL possible chars to type
    const result = new Set(
      this.allKeys(withSpace)
        .map((k) =>
          Object.values(k.keyChars)
            .map((s) => s.value)
            .filter((v) => v !== 'Dead'),
        )
        .flat(),
    )
    return result
  }

  get primaryLanguage() {
    return layoutsConfig[this.os][this.layoutId].primaryLanguage
  }

  get title() {
    return getLayoutsMetadata(this.os, i18n.global.locale.value)[this.layoutId].title
  }

  supportsLanguage(languageCode: LanguageCode, skipNonEssential = true) {
    const config = allLangConfig[languageCode]
    let languageLetters = (config.lowerLetters + config.upperLetters)
      .split('')
      .filter((c) => !config.nonEssentialLetters || !config.nonEssentialLetters.includes(c))

    let unsupportedChars = []
    let deadsInvolved = false
    for (const letter of languageLetters) {
      const keysToType = this.getKeysToType(new Char(letter))
      if (keysToType && keysToType.length > 1) {
        deadsInvolved = true
      } else if (keysToType === null) {
        unsupportedChars.push(letter)
      }
    }

    return {
      supports: !unsupportedChars.length,
      deadsInvolved,
      unsupportedChars,
    }
  }

  supportedLanguageCodes(): { languageCode: LanguageCode; throughDead: boolean }[] {
    // handle buggy Russian-PC ansi separately
    if (this.os === OS.mac && this.format === KeyboardFormat.ANSI && this.layoutId === 'russian_pc') {
      return [{ languageCode: 'ru', throughDead: false }]
    }

    const languages = allLangConfig
    let result: { languageCode: LanguageCode; throughDead: boolean }[] = []

    for (const languageCode in languages) {
      const code = languageCode as LanguageCode
      const support = this.supportsLanguage(code)
      if (support.supports) {
        result.push({ languageCode: code, throughDead: support.deadsInvolved })
      }
    }
    const primaryLanguageIndex = result.findIndex((l) => l.languageCode === this.primaryLanguage)
    if (primaryLanguageIndex !== -1) {
      const primaryLanguageData = result[primaryLanguageIndex]
      delete result[primaryLanguageIndex]
      result = result.filter((item) => !!item)
      result.sort((a, b) => Number(a.throughDead) - Number(b.throughDead))
      result.unshift(primaryLanguageData)
    }
    return result
  }

  get supportsOptionLayer() {
    if (this._supportsOptionLayer === true) {
      // means layout was manually set to suport AltGr
      return true
    }
    for (const key of this.allKeys()) {
      for (const layer of [Layer.Option, Layer.ShiftOption, Layer.CapsOption, Layer.CapsShiftOption]) {
        if (key.keyChars[layer].value) {
          return true
        }
      }
    }
    return false
  }

  isEqualDeadKeys(other: KeyboardLayout) {
    for (const key of this.allKeys()) {
      const otherKey = other.keymap.get(key.code)

      for (const l in key.keyChars) {
        const layer = l as unknown as Layer
        if (otherKey) {
          const thisKeyChar = key.keyChars[layer]
          const otherKeyChar = otherKey.keyChars[layer]
          if (thisKeyChar.isDead() && otherKeyChar.isDead() && !thisKeyChar.isEqual(otherKeyChar)) {
            return false
          }
        } else {
          if (key.keyChars[layer].isDead()) {
            return false
          }
        }
      }
    }

    return true
  }

  isEqual(other: KeyboardLayout) {
    if (!this.isEqualDeadKeys(other)) {
      return false
    }

    for (const key of this.allKeys()) {
      const otherKey = other.keymap.get(key.code)
      if (!otherKey || !key.isEqual(otherKey)) {
        return false
      }
    }

    return true
  }

  getSymbol(keyCode: TypeableKeyCode, layer: Layer) {
    return this.keymap.get(keyCode)?.keyChars[layer] ?? undefined
  }

  getChar(keyCode: TypeableKeyCode, layer: Layer) {
    return this.keymap.get(keyCode)?.keyChars[layer].value
  }

  getKeysToType(char: Char): LayeredKeyCode[] | null {
    if (char.value === ' ') {
      return [new LayeredKeyCode('Space', Layer.Default)]
    }

    for (const layer of allLayersByPriority) {
      for (const key of this.keymap.values()) {
        if (key.keyChars[layer].char.isEqual(char)) {
          return [new LayeredKeyCode(key.code, layer)]
        }
      }
    }

    // try dead keys
    for (const layer of allLayersByPriority) {
      for (const key of this.keymap.values()) {
        if (key.keyChars[layer].isDead()) {
          const charIndex = key.keyChars[layer].deadModifications?.[1].findIndex((m) => m.isEqual(char))
          if (charIndex !== undefined && charIndex !== -1) {
            const nextKeypress = this.getKeysToType(key.keyChars[layer].deadModifications![0][charIndex])?.[0]
            if (nextKeypress) {
              return [new LayeredKeyCode(key.code, layer), nextKeypress]
            }
          }
        }
      }
    }

    return null
  }

  getModifierKeyTitle(keyCode: ModifierKeyCode, withSide = false) {
    const leftLocalized = withSide ? i18n.global.t('left').toLocaleLowerCase() + ' ' : ''
    const rightLocalized = withSide ? i18n.global.t('right').toLocaleLowerCase() + ' ' : ''

    if (keyCode === 'AltGraph') {
      return 'AltGr'
    }
    if (keyCode === 'AltLeft') {
      return leftLocalized + (this.os === OS.mac ? 'option' : 'Alt')
    }
    if (keyCode === 'AltRight') {
      return rightLocalized + (this.os === OS.mac ? 'option' : 'Alt')
    }
    if (keyCode === 'ShiftLeft') {
      return leftLocalized + (this.os === OS.mac ? 'shift' : 'Shift')
    }
    if (keyCode === 'ShiftRight') {
      return rightLocalized + (this.os === OS.mac ? 'shift' : 'Shift')
    }
    if (keyCode === 'CapsLock') {
      return this.os === OS.mac ? 'caps lock' : 'Caps'
    }
  }

  allCharsSupported(chars: string[]) {
    for (const char of chars) {
      let found = false
      for (const key of this.allKeys()) {
        for (const layer in key.keyChars) {
          const supportedLayer = layer as unknown as Layer
          if (key.keyChars[supportedLayer].value === char) {
            found = true
            break
          }
        }
      }

      if (!found) {
        // DEV
        // console.log('not found char: ', char)
        // console.log('all keys:', this.allKeys)
        return false
      }
    }

    return true
  }

  static template() {
    // @ts-expect-error on purpose
    return new KeyboardLayout(getUserOS(), 'standard', KeyboardFormat.Unknown, '', new Map())
  }

  static fromLayoutDefinition(definition: LayoutDefinition) {
    const layoutMeta = getLayoutsMetadata(definition.os, definition.languageCode)[definition.layoutId]

    const layoutConfig = parseTxtLayoutConfig(definition.os, definition.layoutId)
    const shortcutsLayoutConfig = layoutMeta.shortcutsLayout ? parseTxtLayoutConfig(definition.os, layoutMeta.shortcutsLayout) : undefined

    return KeyboardLayout.fromConfig(definition, { layout: layoutConfig, shortcutsLayout: shortcutsLayoutConfig })
    // return KeyboardLayout.template()
  }

  // for dev only

  addKeyValue(layer: Layer, value: string, keyCode: TypeableKeyCode) {
    if (!typeableKeyCodes.includes(keyCode)) {
      return
    }

    const key = this.keymap.get(keyCode) ?? new KeyboardKey(keyCode)
    key.keyChars[layer].setValue(value)
    this.keymap.set(keyCode, key)
  }

  static fromConfig(definition: LayoutDefinition, config: { layout: LayoutConfig; shortcutsLayout?: LayoutConfig }) {
    const parseKeyMap = (layout: LayoutConfig): Map<TypeableKeyCode, KeyboardKey> => {
      const keys = new Map<TypeableKeyCode, KeyboardKey>()

      for (const [keyCode, val] of Object.entries(layout)) {
        const value = getByKeyboardFormat(val, definition.format)
        if (value === null) {
          continue
        }
        const key = new KeyboardKey(keyCode as TypeableKeyCode)
        key.keyChars = value
        keys.set(keyCode as TypeableKeyCode, key)
      }

      return keys
    }

    const keyMap = parseKeyMap(config.layout)

    return new KeyboardLayout(
      definition.os,
      definition.layoutId,
      definition.format,
      definition.languageCode,
      keyMap,
      config.shortcutsLayout ? parseKeyMap(config.shortcutsLayout) : undefined,
    )
  }
}

/**
 * SHIFT STATES:
 * 
 * - default
 *   • default
 *   • shift
 *   • option (alt+ctrl or altGr)
 *   • shift+option (shift+alt+ctrl or shift+altGr)

 * - capsLock
 *   • default
 *   • shift
 *   • option (alt+ctrl or altGr)
 *   • shift+option (shift+alt+ctrl or shift+altGr)
 * 
 * NOTE: ability to alternate input methods
 * 
 */
