// store all user layous
// add new layout
// rm layout
// provide all needed metadata easily

import type { FullTypingResult } from '@/helpers/Trainer'
import { KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { CourseStats, type SerializedCourseStats, type StringifiedSerializedCourseStats } from '@/types/CourseStats'
import { LayoutDefinition } from '@/types/LayoutDefinition'
import type { CharTypingResult } from '@/types/typing-result/CharTypingResult'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from './userStore'

export type LayoutData = {
  definition: LayoutDefinition
  layout: KeyboardLayout
  stats: CourseStats
}

export const useCourseStore = defineStore('course', () => {
  const router = useRouter()
  const userStore = useUserStore()

  const courses = ref<LayoutData[]>([])
  const currentCourseIndex = ref<number>(-1)
  const current = computed<LayoutData>(() => {
    // either real or template as placeholder

    if (courses.value[currentCourseIndex.value]) {
      return courses.value[currentCourseIndex.value] as LayoutData
    }

    return {
      definition: LayoutDefinition.template(),
      layout: KeyboardLayout.template(),
      stats: CourseStats.template(),
    }
  })
  const initialized = ref(false)

  const reset = () => {
    courses.value = []
    currentCourseIndex.value = -1
  }

  // current is always there, but sometimes we need to know if current is real or template
  const currentExists = computed(() => !!courses.value[currentCourseIndex.value])

  const init = (rawLayouts: [rawDefinition: string, rawStats: SerializedCourseStats | StringifiedSerializedCourseStats][], index: number) => {
    let parsedLayouts: LayoutData[] = []

    for (const rawLayout of rawLayouts) {
      const definition = LayoutDefinition.fromStringId(rawLayout[0])

      const layout = KeyboardLayout.fromLayoutDefinition(definition)

      parsedLayouts.push({
        definition,
        layout,
        stats: CourseStats.parse(rawLayout[1]),
      })
    }

    parsedLayouts.sort((a, b) => a.stats.added.diff(b.stats.added))

    courses.value = parsedLayouts
    currentCourseIndex.value = index
    initialized.value = true
  }

  const waitForInit = (callback: () => void) => {
    if (initialized.value) {
      callback()
      return
    }

    const unwatch = watch(initialized, (value) => {
      if (value) {
        callback()
        unwatch()
      }
    })
  }

  const saveTraining = (fullTypingResult: FullTypingResult, result: CharTypingResult) => {
    current.value.stats.logTraining(fullTypingResult, result, current.value)
    userStore.saveCourseStats(current.value.definition.toStringId(), current.value.stats.serialize())
  }
  const markInfoLessonDone = (lessonId: string) => {
    current.value.stats.logInfoLesson(lessonId)
    userStore.saveCourseStats(current.value.definition.toStringId(), current.value.stats.serialize())
  }

  const iterateCurrentLayout = () => {
    if (currentCourseIndex.value === -1) {
      return
    }
    currentCourseIndex.value = (currentCourseIndex.value + 1) % courses.value.length
  }

  const setCurrentLayout = (index: number) => {
    currentCourseIndex.value = index
    userStore.saveSettings({ currentCourseIndex: index })
  }

  const addLayout = (layout: KeyboardLayout, definition: LayoutDefinition) => {
    if (courses.value.find((l) => l.definition.equal(definition))) {
      return
    }

    const layoutData: LayoutData = { definition, layout, stats: CourseStats.template() }
    courses.value.push(layoutData)
    currentCourseIndex.value = courses.value.length - 1

    userStore.saveData({
      courses: Object.assign(userStore.allUserData.courses ?? {}, { [definition.toStringId()]: layoutData.stats.serialize() }),
      settings: Object.assign(userStore.allUserData.settings, { currentCourseIndex: courses.value.length - 1 }),
    })
  }

  const deleteCurrentLayout = async () => {
    if (currentCourseIndex.value === -1) {
      return
    }

    if (courses.value.length === 1) {
      await router.push({ name: 'layoutSetup' })
      courses.value = []
      currentCourseIndex.value = -1
    } else {
      courses.value.splice(currentCourseIndex.value, 1)
      currentCourseIndex.value = courses.value.length - 1
    }

    userStore.saveData({
      courses: Object.fromEntries(courses.value.map((course) => [course.definition.toStringId(), course.stats.serialize()])),
      settings: Object.assign(userStore.allUserData.settings, { currentCourseIndex: currentCourseIndex.value }),
    })
  }

  return {
    courses,
    currentCourseIndex,
    current,
    currentExists,
    init,
    reset,
    initialized,
    saveTraining,
    markInfoLessonDone,
    waitForInit,
    iterateCurrentLayout,
    setCurrentLayout,
    addLayout,
    deleteCurrentLayout,
  }
})
