<script setup lang="ts">
import { shortcutsConfig } from '@/configs/shortcuts-config'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { useShortcutStore } from '@/stores/shortcutStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { watch } from 'vue'

type Props = {
  modalId: string
}
const props = withDefaults(defineProps<Props>(), {})

const model = defineModel<boolean>()

const shortcutStore = useShortcutStore()

watch(
  model,
  (newValue) => {
    if (newValue) {
      shortcutStore.activeModalId = props.modalId
      document.body.classList.add('modal-open')
    } else if (shortcutStore.activeModalId === props.modalId) {
      shortcutStore.activeModalId = null
      document.body.classList.remove('modal-open')
    }
  },
  { immediate: true },
)

const hideModal = () => {
  model.value = false
}

const onModalAreaClick = (event: Event) => {
  if (event.target === event.currentTarget) {
    hideModal()
  }
}

const discardShortcut = new KeyboardShortcut(
  shortcutsConfig.discard,
  () => {
    hideModal()
  },
  props.modalId,
)

useKeyboardShortcuts([discardShortcut])
</script>

<template>
  <Teleport to="body">
    <div class="overlay" v-show="model" @click="onModalAreaClick">
      <div class="modal">
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
:global(body.modal-open) {
  overflow: hidden;
}

.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--c-modal-backdrop);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: var(--c-background);
  border-radius: var(--br-xl);
  border: 1px solid var(--c-secondary-border);
}
</style>
