<script setup lang="ts">
import HandsHint from '@/components/HandsHint.vue'
import { Finger, Hand, logicalMapping, optimizedMapping } from '@/helpers/keyboards/FingerMapping'
import { isTypeable, type KeyCode, type ModifierKeyCode } from '@/helpers/keyboards/KeyCode'
import { KeyboardFormat, KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { Layer, buildLayerFromModifiers } from '@/helpers/keyboards/Layer'
import { KeyPressHelper } from '@/helpers/press-helper'
import { getModifierKeys } from '@/helpers/press-hint-modifiers-helper'
import { isMacOS } from '@/helpers/user-agent-utils'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { OS } from '@/types/main-types'
import { size } from 'lodash-es'
import { computed, nextTick, onMounted, onUnmounted, ref, watch, watchEffect, type Ref, type StyleValue } from 'vue'
import { useRoute } from 'vue-router'
import type { TippyOptions } from 'vue-tippy'

export interface Props {
  layout?: KeyboardLayout
  handlePresses?: boolean | 'layer-only'
  keyToPress?: LayeredKeyCode | null
  pressModifiersOnly?: boolean
  showHands?: boolean | 'layout-setup'
  showKeyboard?: boolean
  showFingerMapping?: boolean
  outlined?: boolean
  unbordered?: boolean
  supportOptionLayer?: boolean
  layer?: Layer // to force specific one
  keyStyleFunc?: (code: KeyCode, value: string, keyboardState: Layer) => StyleValue
  keyTooltipFunc?: (code: KeyCode, keyboardState: Layer) => TippyOptions | null
}

const props = withDefaults(defineProps<Props>(), {
  handlePresses: false,
  showHands: false,
  keyToPress: null,
  pressModifiersOnly: false,
  showKeyboard: true,
  showFingerMapping: false,
  outlined: false,
  unbordered: false,
  supportOptionLayer: false,
})

const isLayoutSetup = computed(() => props.showHands === 'layout-setup')

const emit = defineEmits(['keydown', 'keyup', 'press']) // press is same as keydown

const courseStore = useCourseStore()
const userStore = useUserStore()
const route = useRoute()
const inTypingView = route.name === 'typing'

const keyPressHelper = new KeyPressHelper(courseStore.current.layout)
onMounted(() => {
  keyPressHelper.reset()
})
watchEffect(() => {
  keyPressHelper.layout = courseStore.current.layout
})

if (!props.layout && !courseStore.current) {
  throw new Error('Keyboard layout is not provided')
}

const layout = computed(() => {
  return props.layout ?? (courseStore.current.layout as KeyboardLayout)
})

const pressedKeys = ref<Set<KeyCode>>(new Set())

const fingerMapping = computed(() => {
  // // priority 1 - finger mapping from props.showFingerMapping
  // if (props.showFingerMapping instanceof FingerMapping) {
  //   return props.showFingerMapping
  // }

  // priority 2 - finger mapping from store
  return userStore.settings.fingerMapping === 'optimized' ? optimizedMapping : logicalMapping
})

// dynamic values, used to animate sequence of presses
const toPress = ref(props.keyToPress)
const modifierKeys = ref<ModifierKeyCode[] | null>(null)
// const interactiveModifiersInterval = ref<undefined | ReturnType<typeof setInterval>>(undefined)

// real current modifiers keys (for hands)
const modifierKeysComputed = computed(() => {
  if (!props.keyToPress) {
    return null
  }
  return getModifierKeys(props.keyToPress, layout.value.os, userStore.settings.fingerMapping, isLayoutSetup.value)
})

const showPressModifiers = () => {
  if (props.keyToPress === null) {
    return
  }
  modifierKeys.value = getModifierKeys(props.keyToPress, layout.value.os, userStore.settings.fingerMapping, isLayoutSetup.value)
}

watch(
  () => props.keyToPress,
  (newVal) => {
    toPress.value = newVal
    if (!newVal) {
      modifierKeys.value = []
    }
    showPressModifiers()
  },
  { immediate: true },
)

const handleKeyDown = (event: KeyboardEvent): void => {
  if (!props.handlePresses) {
    return
  }

  // avoid keys sticking
  if ((event.ctrlKey && event.key !== 'Control') || (event.metaKey && event.key !== 'Meta') || (event.altKey && event.key !== 'Alt' && !isMacOS())) {
    return
  }

  if (event.key === 'CapsLock') {
    capsPressed.value = !capsPressed.value
  } else if (event.key === 'Shift') {
    if (event.code === 'ShiftLeft') {
      shiftLeftOn.value = true
    } else if (event.code === 'ShiftRight') {
      shiftRightOn.value = true
    }
    shiftPressed.value = true
  } else if (event.key === 'Alt') {
    altPressed.value = true
  } else if (event.key === 'AltGraph') {
    altGrPressed.value = true
  } else if (event.key === 'Control') {
    controlPressed.value = true
  }

  if (props.handlePresses === 'layer-only') {
    return
  }

  event.preventDefault()

  const pressEvent = keyPressHelper.produceStandartizedKeyPress(event, currentLayer.value)
  pressedKeys.value.add(pressEvent.keyCode)

  // if wrong keypress, repeat press sequence animation
  if (
    isLayoutSetup.value &&
    (pressEvent.keyCode !== props.keyToPress?.keyCode || pressEvent.layer !== props.keyToPress?.layer) &&
    isTypeable(pressEvent.keyCode)
  ) {
    showPressModifiers()
  }

  emit('keydown', pressEvent)
  emit('press', pressEvent)
}

const handleKeyUp = (event: KeyboardEvent): void => {
  if (!props.handlePresses) {
    return
  }

  const keyCode = keyPressHelper.getConsistentKeyCode(event)

  if (event.key === 'CapsLock' && isMacOS()) {
    capsPressed.value = false
  } else if (event.key === 'Shift') {
    if (keyCode === 'ShiftLeft') {
      shiftLeftOn.value = false
    } else if (keyCode === 'ShiftRight') {
      shiftRightOn.value = false
    }
    shiftPressed.value = shiftLeftOn.value || shiftRightOn.value
  } else if (event.key === 'Alt') {
    altPressed.value = false
  } else if (event.key === 'AltGraph') {
    altGrPressed.value = false
  } else if (event.key === 'Control') {
    controlPressed.value = false
  }

  if (props.handlePresses === 'layer-only') {
    return
  }

  pressedKeys.value.delete(keyCode)
}

// Dynamic keyboard size

const keyboardWrapper: Ref<null | HTMLElement> = ref(null)

function setUnit() {
  // just a custom magic number, that is in sync with css calculations
  const UNIT_FACTOR = props.unbordered ? 151.5 : 154.5

  if (!keyboardWrapper.value) {
    return
  }
  let keyboardWidth
  if (keyboardWrapper.value) {
    keyboardWidth = window.getComputedStyle(keyboardWrapper.value, null).getPropertyValue('width')
    const unit = Number.parseFloat(keyboardWidth) / UNIT_FACTOR

    ;(document.querySelector('body') as HTMLBodyElement).style.setProperty('--keyboard-size-unit', `${unit}px`)
  }
}

onMounted(() => {
  window.addEventListener('resize', setUnit)
  document.addEventListener('keydown', handleKeyDown)
  document.addEventListener('keyup', handleKeyUp)
  nextTick(() => setUnit())
})

onUnmounted(() => {
  window.removeEventListener('resize', setUnit)
  document.removeEventListener('keydown', handleKeyDown)
  document.removeEventListener('keyup', handleKeyUp)
})

// Layout layer state

const shiftLeftOn = ref(false)
const shiftRightOn = ref(false)

const capsPressed = defineModel<boolean>('capsPressed', { default: false })
const shiftPressed = defineModel<boolean>('shiftPressed', { default: false })
const altPressed = defineModel<boolean>('altPressed', { default: false })
const altGrPressed = defineModel<boolean>('altGrPressed', { default: false })
const controlPressed = defineModel<boolean>('controlPressed', { default: false })

const optionPressed = computed(() => {
  // consider windows/linux altGr OR alt+control as Mac's option
  return layout.value.os === OS.mac ? altPressed.value : altGrPressed.value || (altPressed.value && controlPressed.value)
})

const currentLayer = computed<Layer>(() => {
  if (props.layer) {
    return props.layer
  }
  const layoutSupportsOptionLayer = layout.value.supportsOptionLayer || props.supportOptionLayer
  return buildLayerFromModifiers({ caps: capsPressed.value, shift: shiftPressed.value, option: optionPressed.value && layoutSupportsOptionLayer })
})

const layoutLayerState = defineModel<Layer>('layoutState')

watch(currentLayer, (layer) => {
  layoutLayerState.value = layer
})

// Keys style

const keyFingerMappingClass = (code: KeyCode) => {
  if (!props.showFingerMapping) {
    return ''
  }

  const mappedFinger = fingerMapping.value?.getFinger(code)
  if (mappedFinger === undefined) {
    return ''
  }

  const { hand, finger } = mappedFinger
  if (hand === Hand.Left) {
    if ([Finger.Middle, Finger.Little].includes(finger)) {
      return `finger-mapping-alternate`
    }
  } else {
    if ([Finger.Index, Finger.Ring].includes(finger)) {
      return `finger-mapping-alternate`
    }
  }

  return ''
}

const keyTooltip = (code: KeyCode) => {
  return props.keyTooltipFunc ? props.keyTooltipFunc(code, currentLayer.value) : null
}

const layoutFormat = computed(() => layout.value.format)

const keyClassNames = (code: KeyCode) => {
  let classNames = [
    code,
    pressedKeys.value.has(code) ? 'pressed' : '', // visually press keys
    keyFingerMappingClass(code), // finger mapping coloring
    layout.value.os, // os-specific key styles, mostly functional kesy
    ['KeyJ', 'KeyF'].includes(code) ? 'with-dots' : '',
  ]

  if (toPress.value?.keyCode === code && !props.pressModifiersOnly) {
    classNames.push('required') // highlight the key that should be pressed next
    if (isLayoutSetup.value) {
      classNames.push('animated')
    }
  }

  if (code === 'ShiftLeft') {
    classNames.push(
      layoutFormat.value === KeyboardFormat.Unknown
        ? 'medium-left-shift'
        : layoutFormat.value === KeyboardFormat.ISO
          ? 'short-left-shift'
          : 'left-shift',
    )
  }

  if (modifierKeys.value && modifierKeys.value.includes(code as ModifierKeyCode)) {
    classNames.push('required')
  }

  if (keyTooltip(code)) {
    classNames.push('with-tooltip')
  }

  return classNames.filter((c) => !!c)
}

const keyCustomCss = (code: KeyCode, value?: string) => {
  return props.keyStyleFunc ? props.keyStyleFunc(code, value ?? code, currentLayer.value) : {}
}
</script>

<template>
  <div ref="keyboardWrapper" class="keyboard">
    <div class="keyboard-inner" :class="[size, inTypingView && 'in-trainer-view', outlined && 'outlined', unbordered && 'unbordered']">
      <div class="keyboard-keys" :style="{ visibility: showKeyboard ? 'visible' : 'hidden' }">
        <div v-for="(row, i) in layout.keyRows" :key="i" class="row">
          <!-- Start of row -->
          <div v-if="i === 1" class="key func-key tab" :class="keyClassNames('Tab')" :style="keyCustomCss('Tab')">
            <div class="label">Tab</div>
          </div>
          <div v-if="i === 2" class="key func-key capslock" :class="keyClassNames('CapsLock')" :style="keyCustomCss('CapsLock')">
            <div class="icon">
              <div class="caps-lock-indicator" :class="{ on: capsPressed }"></div>
            </div>
            <div class="label" v-if="layout.os === OS.mac">Caps Lock</div>
            <div class="label" v-else>Caps</div>
          </div>
          <div v-if="i === 3" class="key func-key" :class="keyClassNames('ShiftLeft')" :style="keyCustomCss('ShiftLeft', 'Shift')">
            <div class="label">Shift</div>
          </div>

          <!-- List of layout keys -->
          <div
            v-for="key in row"
            :key="key.code"
            class="key"
            :class="keyClassNames(key.code)"
            :style="keyCustomCss(key.code, key.renderKeycap(currentLayer, layout.os))"
            v-tippy="keyTooltip(key.code)"
          >
            <div class="label">
              <i v-if="key.renderKeycap(currentLayer, layout.os) === 'Dead'" class="fi fi-rr-skull"></i>
              <div v-else>
                {{ key.renderKeycap(currentLayer, layout.os) }}
              </div>
            </div>
          </div>

          <!-- End of row -->
          <div v-if="i === 0" class="key func-key align-right backspace" :class="keyClassNames('Backspace')" :style="keyCustomCss('Backspace')">
            <div class="label" v-if="layout.os === OS.mac">Delete</div>
            <div class="icon backspace" v-else>
              <svg>
                <use href="#icon-backspace" />
              </svg>
            </div>
          </div>
          <div
            v-if="i === 1 && layout.format === KeyboardFormat.ISO"
            class="key iso-enter"
            :class="keyClassNames('Enter')"
            :style="keyCustomCss('Enter')"
          >
            <svg class="icon enter" width="21" height="16" viewBox="0 0 21 16">
              <use href="#icon-enter" />
            </svg>
          </div>
          <div
            v-if="i === 2 && layout.format !== KeyboardFormat.ISO"
            class="key func-key align-right enter"
            :class="keyClassNames('Enter')"
            :style="keyCustomCss('Enter')"
          >
            <div class="label">{{ layout.os === OS.mac ? 'Return' : 'Enter' }}</div>
          </div>
          <div
            v-if="i === 3"
            class="key func-key align-right right-shift"
            :class="keyClassNames('ShiftRight')"
            :style="keyCustomCss('ShiftRight', 'Shift')"
          >
            <div class="label">Shift</div>
          </div>
        </div>

        <!-- bottom row -->
        <div class="row">
          <template v-if="layout.os === OS.mac">
            <div class="key func-key bottom align-right" :class="keyClassNames('ControlLeft')" :style="keyCustomCss('ControlLeft', 'Control')">
              <div class="icon">⌃</div>
              <div class="label">Control</div>
            </div>
            <div class="key func-key bottom align-right" :class="keyClassNames('AltLeft')" :style="keyCustomCss('AltLeft', 'Alt')">
              <div class="icon">⌥</div>
              <div class="label">Option</div>
            </div>
            <div class="key func-key bottom align-right" :class="keyClassNames('MetaLeft')" :style="keyCustomCss('MetaLeft', 'Meta')">
              <div class="icon">⌘</div>
              <div class="label">Command</div>
            </div>
          </template>
          <template v-else>
            <div class="key func-key bottom" :class="keyClassNames('ControlLeft')" :style="keyCustomCss('ControlLeft', 'Control')">
              <div class="label">Ctrl</div>
            </div>
            <div class="key func-key bottom" :style="keyCustomCss('MetaLeft', 'Meta')">
              <div class="icon" :class="keyClassNames('MetaLeft')">
                <svg>
                  <use :href="layout.os === OS.win ? '#icon-win' : '#icon-lnx'" />
                </svg>
              </div>
            </div>
            <div class="key func-key bottom alt" :class="keyClassNames('AltLeft')" :style="keyCustomCss('AltLeft', 'Alt')">
              <div class="label">Alt</div>
            </div>
          </template>

          <div class="key space" :class="keyClassNames('Space')" :style="keyCustomCss('Space', ' ')" />

          <template v-if="layout.os === OS.mac">
            <div class="key func-key bottom" :class="keyClassNames('MetaRight')" :style="keyCustomCss('MetaRight', 'Meta')">
              <div class="icon">⌘</div>
              <div class="label">Command</div>
            </div>
            <div class="key func-key bottom" :class="keyClassNames('AltRight')" :style="keyCustomCss('AltRight', 'Alt')">
              <div class="icon">⌥</div>
              <div class="label">Option</div>
            </div>
            <div class="key func-key bottom" :class="keyClassNames('ControlRight')" :style="keyCustomCss('ControlRight', 'Control')">
              <div class="icon">⌃</div>
              <div class="label">Control</div>
            </div>
          </template>
          <template v-else>
            <div class="key func-key bottom alt" :class="keyClassNames('AltGraph')" :style="keyCustomCss('AltGraph', 'Alt')">
              <div class="label">Alt Gr</div>
            </div>
            <div class="key func-key bottom" :class="keyClassNames('MetaRight')" :style="keyCustomCss('MetaRight', 'Meta')">
              <div
                class="icon menu-icon"
                :class="{
                  win: layout.os === OS.win,
                  lnx: layout.os === OS.lnx,
                }"
              >
                <svg>
                  <use href="#icon-win-menu" />
                </svg>
              </div>
            </div>
            <div class="key func-key bottom align-right" :class="keyClassNames('ControlRight')" :style="keyCustomCss('ControlRight', 'Control')">
              <div class="label">Ctrl</div>
            </div>
          </template>
        </div>

        <!-- SVG for some keys -->
        <div class="svg-sprite">
          <svg xmlns="http://www.w3.org/2000/svg">
            <symbol id="icon-backspace" viewBox="0 0 64 64">
              <g>
                <path
                  fill="currentColor"
                  d="m60 30h-51.17l8.58-8.59a2 2 0 0 0 -2.82-2.82l-12 12a2.06 2.06 0 0 0 -.59 1.8 2.16 2.16 0 0 0 .55 1l12 12a2 2 0 1 0 2.82-2.82l-8.54-8.57h51.17a2 2 0 0 0 0-4z"
                />
              </g>
            </symbol>
            <symbol id="icon-enter" viewBox="0 0 21 16">
              <path
                fill="currentColor"
                d="M18 .5a2.5 2.5 0 012.5 2.34V9a2.5 2.5 0 01-2.34 2.5H2.4l6.35 3.56c.2.12.3.38.22.6l-.03.09a.5.5 0 01-.6.22l-.09-.03-8-4.5a.5.5 0 01-.07-.82l.07-.06 8-4.5a.5.5 0 01.57.82l-.07.06L2.4 10.5H18c.78 0 1.42-.6 1.5-1.36V3c0-.78-.6-1.42-1.36-1.5h-2.56a.5.5 0 01-.1-1H18z"
              />
            </symbol>
          </svg>
        </div>
      </div>

      <HandsHint
        class="hands-hint"
        v-if="props.showHands"
        :layout="layout"
        :key-to-press="keyToPress"
        :modifiers="modifierKeysComputed"
        :pressModifiersOnly="pressModifiersOnly"
        :isLayoutSetup="isLayoutSetup"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$min-keyboard-size-unit: 0px;

.svg-sprite {
  display: none;
}

.keyboard {
  width: 100%;
  text-align: center;
  font-size: 0;
}

.keyboard-inner {
  display: inline-block;
  position: relative;
  font-weight: 400;
  color: var(--c-keyboard-key-text);
  --c-keyboard-background: var(--c-background);
  font-size: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.75);
  line-height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.75);

  &:hover {
    cursor: default;
  }

  // so keyboard won't be stuck to the bottom and blink
  &.in-trainer-view {
    padding-bottom: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 10);
  }

  &.outlined {
    .keyboard-keys {
      background-image: unset !important;
      padding-bottom: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1.5);
      // margin-bottom: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1);
      // padding: 0 !important;
      box-shadow: none !important;
    }

    &:not(.unbordered) {
      .keyboard-keys {
        border: 1px solid var(--c-divider);
      }
    }
    &.unbordered {
      .keyboard-keys {
        padding: 0;
      }
    }

    .key {
      box-shadow: none !important;
      --background-color: var(--c-keyboard-background);
      --border-color: var(--c-divider);
      --shadow-color: transparent;
      --text-color: var(--c-keyboard-key-text);
    }
  }
}

.keyboard-keys {
  display: grid;
  background-image: linear-gradient(to bottom, var(--c-keyboard-gradient-from) 0%, var(--c-keyboard-gradient-to) 100%);
  row-gap: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1.5);
  border-radius: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2);
  padding: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1.5);
  padding-bottom: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.5);
  box-shadow: inset 0 calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * -1) var(--c-keyboard-bottom-shadow);

  .row {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: end;
    column-gap: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1.5);
  }

  .key {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 9);
    height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 9);
    border-radius: var(--keyboard-size-unit, $min-keyboard-size-unit);
    padding: var(--keyboard-size-unit, $min-keyboard-size-unit) calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1.5);

    // variables
    --background-color: unset;
    --border-color: var(--c-keyboard-key-border);
    --shadow-color: var(--c-keyboard-key-shadow);
    --text-color: var(--c-keyboard-key-text);
    --background: linear-gradient(
      to bottom,
      var(--background-color, var(--c-keyboard-key-gradient-from)) 0%,
      var(--background-color, var(--c-keyboard-key-gradient-to)) 100%
    );

    border: 1px solid var(--border-color);
    box-shadow: inset 0 calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * -0.4) var(--shadow-color);
    background: var(--background);
    color: var(--text-color);
    transition: transform 0.2s ease;

    &.with-tooltip {
      &:hover {
        transform: scale(1.1);
      }
    }

    .label {
      font-feature-settings: 'zero' 1;
    }

    &.with-dots {
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2);
        height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 0.4);
        background-color: var(--text-color);
        opacity: 0.3;
        bottom: 20%;
        left: 0;
        right: 0;
        border-radius: var(--br-full);
        margin: auto;
      }
    }

    &.iso-enter {
      position: relative;
      box-shadow: none;
      border-bottom-right-radius: 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
      }

      &::before {
        top: 100%;
        right: -1px;
        border: 1px solid var(--border-color);
        border-top: none;
        background: var(--background-color, var(--c-keyboard-key-gradient-to));
        width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 7);
        height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 10.5 + 1px);
        border-bottom-right-radius: var(--keyboard-size-unit, $min-keyboard-size-unit);
        border-bottom-left-radius: var(--keyboard-size-unit, $min-keyboard-size-unit);
        box-shadow: inset 0 calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * -0.5) var(--shadow-color);
      }

      &::after {
        top: 0;
        right: 0;
        background: var(--background);
        width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 7 - 2px);
        height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 9);
        border-top-right-radius: var(--keyboard-size-unit, $min-keyboard-size-unit);
      }
    }

    // overwrites

    &.finger-mapping-alternate {
      --background: linear-gradient(
        to bottom,
        var(--background-color, var(--c-keyboard-key-gradient-from-alternate)) 0%,
        var(--background-color, var(--c-keyboard-key-gradient-to-alternate)) 100%
      );
      --shadow-color: var(--c-keyboard-key-shadow-alternate);
    }

    &.required {
      --background-color: var(--c-keyboard-key-bg-to-press);
      --border-color: var(--c-keyboard-key-to-press-border);
      --text-color: var(--c-keyboard-key-text-on-active);
      --shadow-color: var(--c-keyboard-key-to-press-shadow);

      &.animated {
        overflow: hidden;
        ::after {
          content: '';
          position: absolute;
          width: calc(var(--keyboard-size-unit) * 10);
          height: calc(var(--keyboard-size-unit) * 10);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: var(--br-full);
          border: 3px solid #fff;
          animation: rippleAnimation 1.5s infinite;
        }
        @keyframes rippleAnimation {
          0% {
            transform: translate(-50%, -50%) scale(0.5);
            opacity: 0.6;
          }
          50% {
            transform: translate(-50%, -50%) scale(1.2);
            opacity: 0;
          }
          100% {
            transform: translate(-50%, -50%) scale(1.5);
            opacity: 0;
          }
        }
      }
    }

    &.pressed {
      height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 8.5);
      padding-bottom: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 0.5);
      box-shadow: none;
      &.iso-enter:before {
        box-shadow: none;
      }
    }

    &.func-key {
      padding-bottom: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1.5);
      font-size: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2);
      line-height: 1;
      font-family: var(--ff-apple);
      padding-top: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1.5);
      padding-bottom: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1.2);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &.pressed {
        padding-bottom: var(--keyboard-size-unit, $min-keyboard-size-unit);
      }

      &.bottom {
        width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 13.67);

        &.alt:not(.mac) {
          align-items: center;
        }
      }

      .label {
        font-weight: 500;
      }

      &.win,
      &.lnx {
        font-size: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.2);
      }

      &.mac {
        justify-content: flex-end;
        .label {
          text-transform: lowercase;
        }
      }

      &.align-right {
        align-items: flex-end;
      }

      .icon {
        margin-bottom: auto;
        font-size: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.5);
        line-height: 1;

        --svg-icon-size: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.25);
        svg {
          width: var(--svg-icon-size);
          height: var(--svg-icon-size);
        }

        &.win,
        &.lnx {
          margin: auto;

          --svg-icon-size: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 3);
        }

        &.menu-icon {
          --svg-icon-size: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.5);
        }

        .caps-lock-indicator {
          width: var(--capslock-indicator-size);
          height: var(--capslock-indicator-size);
          background-color: var(--c-icon);
          border-radius: 50%;
          margin-top: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 0.25);

          &.on {
            background-color: var(--c-success-text);
          }
        }
      }
    }

    // special keys width
    &.backspace,
    &.tab {
      width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 15);
    }
    &.left-shift,
    &.right-shift {
      width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 22.5);
    }
    &.enter {
      width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 17.5);
    }
    &.capslock {
      width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 17);
      --capslock-indicator-size: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 1);

      &:not(.mac) .icon {
        margin-left: auto;
        margin-bottom: calc(-1.8 * var(--capslock-indicator-size));
        position: relative;
        bottom: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.25);
      }
    }
    &.short-left-shift {
      width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 12);
    }
    &.medium-left-shift {
      width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 18);
    }
    &.space {
      width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 60.5);
    }

    .icon {
      &.backspace,
      &.tab {
        --svg-icon-size: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 3.5) !important;
        margin: 0 auto !important;
      }

      &.enter {
        z-index: 1;
        width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 3.5);
        height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.5);
      }

      &.capslock {
        width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.5);
        height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 3.5);
      }

      &.shift {
        width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.5);
        height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 2.5);
      }
    }
  }
}

:deep(.hand) {
  position: absolute;
  top: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 23);
  width: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 80);
  height: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 84);

  &.left {
    left: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 8);
  }

  &.right {
    left: calc(var(--keyboard-size-unit, $min-keyboard-size-unit) * 76);
  }
}
</style>
