<script setup lang="ts">
import Button from '@/components/Button.vue'
import DropdownLanguageSelect from '@/components/DropdownLanguageSelect.vue'
import DropdownLayoutSelect from '@/components/DropdownLayoutSelect.vue'
import DropdownSystemSelect from '@/components/DropdownSystemSelect.vue'
import Keyboard from '@/components/Keyboard.vue'
import { logAnalyticsEvent } from '@/helpers/analytics'
import { filterCandidates, getDiffKeys, keyStyleBeforeBegin, keyStyleStepFormat, keyStyleStepSystem } from '@/helpers/keyboard-setup-helper'
import { isTypeable, typeableKeyCodes, type KeyCode, type ModifierKeyCode, type TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { KeyboardFormat, KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { isOptionLayer, Layer } from '@/helpers/keyboards/Layer'
import { KeyPressHelper } from '@/helpers/press-helper'
import { getModifierKeys } from '@/helpers/press-hint-modifiers-helper'
import { getUserOS } from '@/helpers/user-agent-utils'
import { allLangConfig } from '@/languages/all-lang-config'
import { type LanguageCode, type LanguageMetadata } from '@/languages/languages-config'
import { getLayoutsMetadata, layoutsConfig } from '@/layouts/layouts-config'
import { useAppStore } from '@/stores/appStore'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { LayoutDefinition } from '@/types/LayoutDefinition'
import { OS, osTitles } from '@/types/main-types'
import anime from 'animejs/lib/anime.es.js'
import { computed, nextTick, onBeforeMount, onMounted, onUnmounted, ref, watch, watchEffect, type StyleValue } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const DELAY_BEFORE_MANUAL_LAYOUT_SELECT_MS = 10_000

const { t, locale } = useI18n()
const router = useRouter()
const courseStore = useCourseStore()
const appStore = useAppStore()
const userStore = useUserStore()

// overflow-hidden, scroll only inside layouts section
onBeforeMount(() => {
  document.body.classList.add('setup')
})
onUnmounted(() => {
  document.body.classList.remove('setup')
})

// live
const userLayout = ref(KeyboardLayout.template())
const currentLayer = ref<Layer>(Layer.Default)

// helpers

const userFormat = computed(() => {
  return userLayout.value.format
})

const userOS = computed(() => {
  return userLayout.value.os
})

const userLanguage = computed(() => {
  return userLayout.value.languageCode
})

const resetUserLayoutKeymap = () => {
  const os = userOS.value
  const format = userFormat.value
  const lang = userLanguage.value
  userLayout.value = KeyboardLayout.template()
  userLayout.value.os = os
  userLayout.value.format = format
  userLayout.value.languageCode = lang
}

// candidates
const allLayouts = ref<KeyboardLayout[]>([])
const currentCandidates = ref<KeyboardLayout[]>([])

// highlights

const highlightedKey = ref<LayeredKeyCode | null>(null)

const highlightModifiersOnly = computed(() => {
  if (!highlightedKey.value || highlightedKey.value.layer === Layer.Default || currentLayer.value === highlightedKey.value.layer) {
    return false
  }
  return true
})

const highlightedModifiers = computed<ModifierKeyCode[]>(() => {
  if (!highlightedKey.value) return []
  return getModifierKeys(highlightedKey.value, userOS.value, userStore.settings.fingerMapping, true)
})

// global steps

enum SetupStep {
  System = 'System',
  Format = 'Format',
  Layout = 'Layout',
  Language = 'Language',
}

const SetupStepNumbers = Object.fromEntries(Object.keys(SetupStep).map((k, i) => [k, i])) as Record<SetupStep, number>

const setupStepTitles: Record<SetupStep, () => string> = {
  [SetupStep.System]: () => t('Onboarding.Step.system'),
  [SetupStep.Format]: () => t('Onboarding.Step.format'),
  [SetupStep.Layout]: () => t('Onboarding.Step.layout'),
  [SetupStep.Language]: () => t('Onboarding.Step.language'),
}

const currentSetupStep = ref<SetupStep | null>(null)

const setupStarted = computed(() => {
  return currentSetupStep.value !== null || setupFinished.value
})

const setupFinished = ref(false)

watch(currentSetupStep, (to) => {
  nextTick(() => {
    // title
    const selectors = {
      title: '.main-view .title',
      optionsWrap: '.options-wrapper',
      bottomRowFnKeys: '.key.func-key.bottom',
    }

    anime.set(selectors.title, {
      opacity: 0,
      translateY: -20,
    })
    anime.set(selectors.optionsWrap, {
      opacity: 0,
    })

    anime({
      targets: selectors.title,
      opacity: 1,
      translateY: 0,
      duration: 200,
      easing: 'linear',
    })
    anime({
      targets: selectors.optionsWrap,
      opacity: 1,
      duration: 200,
      easing: 'linear',
    })
  })
})

const onStepClick = (step: SetupStep) => {
  // disable current and next steps
  if (!setupFinished.value && (!currentSetupStep.value || SetupStepNumbers[currentSetupStep.value] <= SetupStepNumbers[step])) {
    return
  }

  if (SetupStepNumbers[step] < SetupStepNumbers[SetupStep.Layout]) {
    // save before cleanup
    const os = userLayout.value.os
    const format = userFormat.value

    userLayout.value = KeyboardLayout.template()
    showSecondaryLayouts.value = false
    selectedLayoutId.value = null
    currentCandidates.value = []
    highlightedKey.value = null
    isAutoSetupActive.value = false
    definedAutomatically.value = false

    // preserve these settings
    userLayout.value.os = os
    userLayout.value.format = format
  }

  currentSetupStep.value = step
  setupFinished.value = false
}

// layout auto setup steps

const isAutoSetupActive = ref(false)

enum AutoSetupStep {
  Filtering = 'Filtering',
  Defined = 'Defined',
  Unsupported = 'Unsupported',
}

const autoSetupStep = computed<AutoSetupStep>(() => {
  if (!isAutoSetupActive.value) {
    return AutoSetupStep.Filtering
  }

  const candidateLayoutIds = currentCandidates.value.map((c) => c.layoutId)
  const allLayoutIds = allLayouts.value.map((l) => l.layoutId)

  if (candidateLayoutIds.length && allLayoutIds.every((l) => !candidateLayoutIds.includes(l))) {
    return AutoSetupStep.Unsupported
  } else if (!candidateLayoutIds.length && allLayouts.value.length) {
    return AutoSetupStep.Unsupported
  }

  if (currentCandidates.value.length === 1) {
    return AutoSetupStep.Defined
  }

  return AutoSetupStep.Filtering
})

watchEffect(() => {
  if (autoSetupStep.value === AutoSetupStep.Unsupported) {
    showManualSelect.value = true
    logAnalyticsEvent('course_setup_layout_unsupported', {})
  }
})

// step 1: system

const continueWithOS = () => {
  currentSetupStep.value = SetupStep.Format
}

// step 2: format

const formatTitles: Record<KeyboardFormat, string> = {
  [KeyboardFormat.ISO]: 'ISO',
  [KeyboardFormat.ANSI]: 'ANSI',
  [KeyboardFormat.Unknown]: 'Unknown',
}

const currentFormat = ref<null | KeyboardFormat>(null)

const continueWithFormat = () => {
  if (!currentFormat.value) {
    return
  }
  const format = currentFormat.value
  userLayout.value.format = format
  currentSetupStep.value = SetupStep.Layout
  logAnalyticsEvent('course_setup_format', { format })

  const layouts = buildLayouts()
  allLayouts.value = layouts
}

// step 3: layout

const showManualSelect = ref(false)
const manualSelectTimeout = ref<null | ReturnType<typeof setTimeout>>(null)

const showSecondaryLayouts = ref(false)

// selected layout
const selectedLayoutId = ref<null | string>(null)

const definedAutomatically = ref(false)

const continueWithLayout = () => {
  if (!selectedLayoutId.value) {
    return
  }
  logAnalyticsEvent('course_setup_layout', { layoutId: selectedLayoutId.value, isAuto: definedAutomatically.value })
  currentSetupStep.value = SetupStep.Language

  nextTick(() => {
    if (
      (!currentLang.value || !filteredLanguages.value.find((l) => l.code === currentLang.value)) &&
      filteredLanguages.value.length &&
      filteredLanguages.value.find((l) => l.code === userLayout.value.primaryLanguage)
    ) {
      currentLang.value = userLayout.value.primaryLanguage
    }
  })
}

watchEffect(() => {
  if (currentCandidates.value.length === 1) {
    const layoutId = currentCandidates.value[0].layoutId
    selectedLayoutId.value = layoutId
    isAutoSetupActive.value = false
    definedAutomatically.value = true
  }
})

watch(selectedLayoutId, (to) => {
  if (selectedLayoutId.value) {
    const definedLayout = allLayouts.value.find((l) => l.layoutId === selectedLayoutId.value)
    if (!selectedLayoutId.value || !definedLayout) {
      return
    }
    userLayout.value = definedLayout
  } else {
    resetUserLayoutKeymap()
  }
})

const onLayoutSelect = (layoutId: string | null) => {
  highlightedKey.value = null
  selectedLayoutId.value = layoutId
  isAutoSetupActive.value = false
  definedAutomatically.value = false
}

const resetLayout = () => {
  selectedLayoutId.value = null
  highlightedKey.value = null
  isAutoSetupActive.value = false
  definedAutomatically.value = false
}

const startAutoDefinition = () => {
  resetUserLayoutKeymap()
  initCandidates()
  selectedLayoutId.value = null
  isAutoSetupActive.value = true

  showManualSelect.value = false
  updateManualSelectTimeout()
}

const updateManualSelectTimeout = () => {
  if (showManualSelect.value) {
    return
  }
  if (manualSelectTimeout.value) {
    clearTimeout(manualSelectTimeout.value)
  }
  manualSelectTimeout.value = setTimeout(() => {
    showManualSelect.value = true
  }, DELAY_BEFORE_MANUAL_LAYOUT_SELECT_MS)
}

// step 4: language

const primaryLayoutLanguage = computed(() => userLayout.value.primaryLanguage)

const compatibleLanguages = computed(() => {
  const definedLayout = allLayouts.value.find((l) => l.layoutId === selectedLayoutId.value)
  if (!definedLayout) {
    return []
  }
  return Object.values(allLangConfig).filter((l) => definedLayout.supportsLanguage(l.code).supports)
})

const filteredLanguages = computed(() => {
  const sortFn = (a: LanguageMetadata, b: LanguageMetadata) =>
    a.code === primaryLayoutLanguage.value ? -1 : b.code === primaryLayoutLanguage.value ? 1 : 0
  return compatibleLanguages.value.sort(sortFn)
})

const currentLang = ref<null | LanguageCode>(null)

const continueWithLanguage = () => {
  if (!currentLang.value) {
    return
  }
  userLayout.value.languageCode = currentLang.value
  logAnalyticsEvent('course_setup_language', { langCode: currentLang.value })
  currentSetupStep.value = null
  setupFinished.value = true
  finish()
}

const finish = () => {
  const definedLayout = allLayouts.value.find((l) => l.layoutId === selectedLayoutId.value)
  if (!definedLayout) {
    return
  }
  const definition = new LayoutDefinition(definedLayout.os, definedLayout.format, definedLayout.layoutId, userLayout.value.languageCode)
  courseStore.addLayout(definedLayout, definition)
  router.push({ name: 'home' })
}

// press handling

const pressHelper = new KeyPressHelper(userLayout.value)

const candidatesFilteringHandler = (event: KeyboardEvent) => {
  const keyCode = pressHelper.getConsistentKeyCode(event)

  const char =
    isTypeable(keyCode) &&
    userLayout.value.os === OS.win &&
    isOptionLayer(currentLayer.value) &&
    // here we check if AltGr char is same as without AltGr, which practically means in real life such a press will produce nothing
    // TODO: ideally we dynamically verify this knowledge (AltGr layer can't contain same char as without AltGr)
    userLayout.value.getChar(keyCode, currentLayer.value - 2) === event.key
      ? ''
      : event.key

  // handle AltGr
  if (userOS.value !== OS.mac && event.code === 'AltRight') {
    if (event.key !== 'AltGraph') {
      // means AltGr not supported
      currentCandidates.value = currentCandidates.value.filter((c) => c.supportsOptionLayer === false)
      updateCandidats()
      return
    } else if (highlightedKey.value?.keyCode === 'AltGraph') {
      // this situation means AltGr supported and it was exact press we expected
      userLayout.value._supportsOptionLayer = true
      updateCandidats()
      return
    }
  }

  if (!typeableKeyCodes.includes(keyCode as TypeableKeyCode) || !currentLayer.value) {
    return
  }

  if (highlightedKey.value?.layer !== currentLayer.value) {
    return
  }

  if (highlightedKey.value?.keyCode !== keyCode) {
    return
  }

  userLayout.value.addKeyValue(currentLayer.value, char, keyCode as TypeableKeyCode)
  updateCandidats()
}

const keyDownListener = (event: KeyboardEvent) => {
  if (event.code === 'Enter') {
    if (!setupStarted.value) {
      currentSetupStep.value = SetupStep.System
    } else if (currentSetupStep.value === SetupStep.System) {
      continueWithOS()
    } else if (currentSetupStep.value === SetupStep.Format) {
      continueWithFormat()
    } else if (currentSetupStep.value === SetupStep.Layout) {
      if (selectedLayoutId.value) {
        continueWithLayout()
      } else if (!isAutoSetupActive.value) {
        startAutoDefinition()
      }
    } else if (currentSetupStep.value === SetupStep.Language) {
      continueWithLanguage()
    }
    return
  }
  if (!isAutoSetupActive.value) {
    return
  }
  return candidatesFilteringHandler(event)
}

const buildLayouts = () => {
  const layouts = Object.keys(getLayoutsMetadata(userOS.value, locale.value)).map((layoutId) => {
    const l = KeyboardLayout.fromLayoutDefinition(new LayoutDefinition(userOS.value, userFormat.value, layoutId, 'en'))
    l.languageCode = l.primaryLanguage
    return l
  })
  return layouts
}

const initCandidates = () => {
  if (userFormat.value === KeyboardFormat.Unknown) {
    return
  }

  currentCandidates.value = allLayouts.value

  resetUserLayoutKeymap()
  updateCandidats()
}

const updateCandidats = () => {
  updateManualSelectTimeout()
  const filtered = filterCandidates(userLayout.value, currentCandidates.value)

  // more layouts popular first
  const layoutIds = Object.keys(layoutsConfig[userLayout.value.os])
  currentCandidates.value = filtered.sort((a, b) => layoutIds.indexOf(a.layoutId) - layoutIds.indexOf(b.layoutId))

  // primary language first
  currentCandidates.value = filtered.sort((a, b) => {
    const aPrimary = a.primaryLanguage === userLayout.value.languageCode
    const bPrimary = b.primaryLanguage === userLayout.value.languageCode

    if (aPrimary && !bPrimary) return -1
    if (bPrimary && !aPrimary) return 1
    return 0
  })

  const filteredLayouts = Object.values(filtered)

  highlightedKey.value = getDiffKeys(userLayout.value, filteredLayouts, true)

  // default to first layout if several exactly same
  // example: "mac_standard" and "mac_us_international_pc" (only dead diffs)
  if (!highlightedKey.value && currentCandidates.value.length > 1) {
    currentCandidates.value = currentCandidates.value.slice(0, 1)
  }

  // NOTE: if needed, catch here rare case with only dead keys diff
  // // // filter out truly unique candidates (could be non-unique by dead keys)
  // let uniqueCandidates = []

  // for (const candidate of filteredLayouts) {
  //   let isUnique = true
  //   for (const uniqueCandidate of uniqueCandidates) {
  //     if (candidate.isEqual(uniqueCandidate)) {
  //       isUnique = false
  //       break
  //     }
  //   }
  //   if (isUnique) {
  //     uniqueCandidates.push(candidate)
  //   }
  // }

  // // if more than 1 truly unique candidates, munual selection needed
  // if (uniqueCandidates.length > 1) {
  //   console.log('Dead keys diffs, manual selection')
  //   logAnalyticsEvent(analyticsEvent.autoKeyboardSetupFail('diffs by dead keys'))
  //   step.value = 3
  //   return
  // }
}

onMounted(() => {
  window.addEventListener('keydown', keyDownListener)
})
onUnmounted(() => {
  window.removeEventListener('keydown', keyDownListener)
})

// helpers

const keyStyleFunc = (code: KeyCode, value: string, keyboardState: Layer): StyleValue => {
  if (!setupStarted.value || (currentSetupStep.value === SetupStep.Layout && autoSetupStep.value === AutoSetupStep.Unsupported)) {
    return keyStyleBeforeBegin(code)
  }
  if (currentSetupStep.value === SetupStep.System) {
    return keyStyleStepSystem(code)
  }
  if (currentSetupStep.value === SetupStep.Format) {
    return keyStyleStepFormat(code)
  }
  return {}
}
</script>

<template>
  <div class="view-wrapper">
    <div class="steps">
      <div
        class="step"
        :class="{
          active: currentSetupStep == step,
          completed: setupFinished || (currentSetupStep && SetupStepNumbers[currentSetupStep] > SetupStepNumbers[step]),
        }"
        v-for="(title, step, index) in setupStepTitles"
        :key="index"
        @click="onStepClick(step)"
      >
        <div class="indicator">
          <div class="index kinda-mono">
            {{ index + 1 }}
          </div>
          <svg class="icon">
            <use href="#icon-check"></use>
          </svg>
        </div>
        <div class="title">
          {{ title() }}
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-view">
        <template v-if="!setupStarted && !setupFinished">
          <div class="title">{{ t('Onboarding.keyboardSetup') }}</div>
          <p class="text">{{ t('Onboarding.keyboardSetupDescription') }}</p>
          <div class="actions">
            <Button size="md" shortcut="Enter" @click="currentSetupStep = SetupStep.System"> {{ t('start') }} </Button>
          </div>
        </template>

        <template v-else-if="currentSetupStep === SetupStep.System">
          <div class="title">{{ t('Onboarding.confirmYourOS', [osTitles[getUserOS()]]) }}</div>
          <div class="actions">
            <Button size="md" shortcut="Enter" @click="continueWithOS"> {{ t('continue') }} </Button>
            <DropdownSystemSelect :selected="userLayout.os" @update:selected="userLayout.os = $event" />
          </div>
        </template>

        <template v-else-if="currentSetupStep === SetupStep.Format">
          <div class="title">{{ t('Onboarding.howEnterLooks') }}</div>
          <div class="options-wrapper">
            <div class="actions no-margin">
              <Button size="md" shortcut="Enter" :disabled="currentFormat === null" @click="continueWithFormat"> {{ t('continue') }} </Button>
            </div>
            <div class="options">
              <div
                @click="
                  () => {
                    userLayout.format = KeyboardFormat.ANSI
                    currentFormat = KeyboardFormat.ANSI
                  }
                "
                class="option format"
                :class="{ active: currentFormat === KeyboardFormat.ANSI }"
              >
                <svg class="icon" viewBox="0 0 130 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    class="enter-border"
                    d="M1 81C1 77.6863 3.68629 75 7 75H123C126.314 75 129 77.6863 129 81V135C129 138.314 126.314 141 123 141H7C3.68629 141 1 138.314 1 135V81Z"
                    stroke-width="2"
                  />
                  <path
                    class="enter-icon"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M109.685 99C108.959 99 108.37 99.583 108.37 100.302V110.069C108.37 111.747 106.996 113.108 105.301 113.108H91.4899L97.614 107.043C98.1276 106.535 98.1276 105.71 97.614 105.202C97.1004 104.693 96.2678 104.693 95.7542 105.202L87.3852 113.489C86.8716 113.998 86.8716 114.822 87.3852 115.331L95.7542 123.619C96.2678 124.127 97.1004 124.127 97.614 123.619C98.1276 123.11 98.1276 122.285 97.614 121.777L91.4899 115.712H105.301C108.449 115.712 111 113.186 111 110.069V100.302C111 99.583 110.411 99 109.685 99Z"
                  />
                  <path
                    class="secondary"
                    d="M1 7C1 3.68629 3.68629 1 7 1H43C46.3137 1 49 3.68629 49 7V61C49 64.3137 46.3137 67 43 67H7C3.68629 67 1 64.3137 1 61V7Z"
                    stroke-width="2"
                  />
                  <path
                    class="secondary"
                    d="M61 7C61 3.68629 63.6863 1 67 1H123C126.314 1 129 3.68629 129 7V61C129 64.3137 126.314 67 123 67H67C63.6863 67 61 64.3137 61 61V7Z"
                    stroke-width="2"
                  />
                </svg>
                <div class="label no-text-selection">{{ t('Onboarding.horizontal') }}</div>
              </div>
              <div
                @click="
                  () => {
                    userLayout.format = KeyboardFormat.ISO
                    currentFormat = KeyboardFormat.ISO
                  }
                "
                class="option format"
                :class="{ active: currentFormat === KeyboardFormat.ISO }"
              >
                <svg class="icon" viewBox="0 0 130 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    class="enter-border"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M61 7C61 3.68629 63.6863 1 67 1H123C126.314 1 129 3.68629 129 7V135C129 138.314 126.314 141 123 141H86.9052C83.5915 141 80.9052 138.314 80.9052 135V71.2105C80.9052 67.8968 78.2189 65.2105 74.9052 65.2105H67C63.6863 65.2105 61 62.5242 61 59.2105V7Z"
                    stroke-width="2"
                  />
                  <path
                    class="enter-icon"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M109.685 33C108.959 33 108.37 33.583 108.37 34.3023V44.0693C108.37 45.7475 106.996 47.1079 105.301 47.1079H91.4899L97.614 41.0434C98.1276 40.5349 98.1276 39.7103 97.614 39.2017C97.1004 38.6932 96.2678 38.6932 95.7542 39.2017L87.3852 47.4893C86.8716 47.9979 86.8716 48.8224 87.3852 49.331L95.7542 57.6186C96.2678 58.1271 97.1004 58.1271 97.614 57.6186C98.1276 57.11 98.1276 56.2855 97.614 55.7769L91.4899 49.7124H105.301C108.449 49.7124 111 47.1859 111 44.0693V34.3023C111 33.583 110.411 33 109.685 33Z"
                  />
                  <path
                    class="secondary"
                    d="M1 7C1 3.68629 3.68629 1 7 1H43C46.3137 1 49 3.68629 49 7V61C49 64.3137 46.3137 67 43 67H7C3.68629 67 1 64.3137 1 61V7Z"
                    stroke-width="2"
                  />
                  <path
                    class="secondary"
                    d="M1 81C1 77.6863 3.68629 75 7 75H63C66.3137 75 69 77.6863 69 81V135C69 138.314 66.3137 141 63 141H7C3.68629 141 1 138.314 1 135V81Z"
                    stroke-width="2"
                  />
                </svg>
                <div class="label no-text-selection">{{ t('Onboarding.vertical') }}</div>
              </div>
            </div>
          </div>
        </template>

        <template v-else-if="currentSetupStep === SetupStep.Layout">
          <div class="title">{{ t('Onboarding.autoSetupTitle') }}</div>
          <div class="text" v-if="!isAutoSetupActive && !selectedLayoutId">{{ t('Onboarding.autoSetupDescription') }}</div>
          <div class="actions">
            <Button
              size="md"
              shortcut="Enter"
              v-if="!selectedLayoutId && !isAutoSetupActive"
              :disabled="!!isAutoSetupActive"
              @click="startAutoDefinition"
            >
              {{ t('start') }}
            </Button>
            <Button size="md" shortcut="Enter" v-if="selectedLayoutId" @click="continueWithLayout"> {{ t('continue') }}</Button>

            <DropdownLayoutSelect
              v-if="(isAutoSetupActive && showManualSelect) || selectedLayoutId"
              :os="userLayout.os"
              :selected="selectedLayoutId"
              @update:selected="onLayoutSelect"
            />
            <Button size="md" variant="outlined" v-if="selectedLayoutId" @click="resetLayout">
              <i class="fi fi-br-rotate-left"></i>
            </Button>
          </div>
        </template>

        <template v-else-if="currentSetupStep === SetupStep.Language">
          <div class="title">{{ t('Onboarding.languagesTitle', filteredLanguages.length) }}</div>
          <div class="text" v-if="filteredLanguages.length > 1">{{ t('Onboarding.languagesDescription') }}</div>
          <div class="actions">
            <Button :disabled="!currentLang" shortcut="Enter" size="md" @click="continueWithLanguage">{{ t('finish') }}</Button>
            <DropdownLanguageSelect v-model:selected="currentLang" :languages="filteredLanguages.map((l) => l.code)" />
          </div>
        </template>

        <div class="keyboard" :class="{ noFormat: currentFormat === null }">
          <div class="presses-instruction" v-if="currentSetupStep === SetupStep.Layout && highlightedKey">
            <template v-if="highlightedKey && highlightedKey.layer === Layer.Default">
              <i18n-t keypath="Onboarding.pressHighlightedKey">
                <span class="key">{{ t('Onboarding.key') }}</span>
              </i18n-t>
            </template>
            <template v-else>
              <template v-if="highlightedModifiers.length">
                {{ t('Onboarding.pressAndHold') }}
                <span v-for="(modifier, i) of highlightedModifiers" :key="modifier">
                  {{ i === 0 ? '' : ` ${t('and')} ` }}
                  <span class="key">{{ userLayout.getModifierKeyTitle(modifier) }}</span>
                </span>
              </template>
              <template v-if="highlightedModifiers.length && !highlightModifiersOnly">
                <i18n-t keypath="Onboarding.andPressHighlighted">
                  <span class="key">{{ t('Onboarding.key') }}</span>
                </i18n-t>
              </template>
            </template>
          </div>
          <Keyboard
            :layout="userLayout"
            :keyStyleFunc="keyStyleFunc"
            :outlined="!selectedLayoutId"
            :handlePresses="true"
            v-model:layoutState="currentLayer"
            :keyToPress="highlightedKey"
            :press-modifiers-only="highlightModifiersOnly"
            :supportOptionLayer="true"
            :showHands="autoSetupStep !== AutoSetupStep.Defined && highlightedKey ? 'layout-setup' : false"
          />
          <div v-if="currentSetupStep === SetupStep.Layout && autoSetupStep === AutoSetupStep.Unsupported" class="keyboard-fail-state">
            {{ $t('Onboarding.layoutNotSupported') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:global(body.setup) {
  overflow: hidden;
}

.view-wrapper {
  flex: 1;
  display: flex;
  width: auto;
  margin: auto;
  --content-height: calc(576px - var(--header-height) - var(--grid-cell) * 3);
  padding-top: var(--grid-cell);
  padding-bottom: var(--grid-cell);
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: var(--fz-md);
  font-weight: 500;
  gap: var(--s-xxs);
  padding-right: calc(var(--s-md) * 1.5);
  height: var(--content-height);
  margin-top: auto;
  margin-bottom: auto;

  .step {
    display: flex;
    align-items: center;
    gap: var(--s-sm);
    cursor: default;
    color: var(--c-text-primary);
    border-radius: var(--br-md);
    padding: var(--s-xs);
    padding-left: var(--s-sm);

    .indicator {
      order: 2;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: var(--c-surface);
      border: 1px solid var(--c-secondary-border);
      font-size: var(--fz-xxs);
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--c-text-secondary);
      .icon {
        display: none;
        width: 12px;
        height: 12px;
      }
    }

    .title {
      order: 1;
      flex: 1;
      display: flex;
      flex-direction: column;
      color: var(--c-text-tertiary);
      font-size: var(--fz-sm);
      font-weight: 400;
    }

    &.completed {
      align-items: flex-start;
      &:hover {
        cursor: pointer;
        background-color: var(--c-surface);
      }
      .title {
        color: var(--c-text-primary);
      }
      .indicator {
        background-color: var(--c-text-primary);
        border: 2px solid var(--c-text-primary);
        .index {
          display: none;
        }
        .icon {
          display: unset;
          color: var(--c-background);
        }
      }
    }

    &.active {
      .title {
        color: var(--c-primary);
        font-weight: 500;
      }
      .indicator {
        border: 1px solid var(--c-primary);
        color: var(--c-text-on-primary);
        background-color: var(--c-primary);
        font-weight: 600;
      }
    }
  }
}

.main {
  background: radial-gradient(50% 50% at 0% 50%, var(--c-surface) 0%, var(--c-background) 100%);
  display: flex;
  position: relative;
  flex: 1;

  &:before {
    content: url('');
    position: absolute;
    width: 1px;
    height: 100%;
    left: -1px;
    top: 0;
    background-image: linear-gradient(180deg, var(--c-background) 0%, var(--c-divider) 50%, var(--c-background) 100%);
  }
}

.main-view {
  --keyb-width: 800px;
  height: var(--content-height);
  margin: auto;
  padding-left: var(--s-lg);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-size: var(--fz-h2);
    font-weight: 500;
  }

  .text {
    margin-top: var(--s-xs);
  }

  .actions {
    margin-top: calc(var(--s-lg));
    display: flex;
    gap: var(--s-md);
    &.no-margin {
      margin-top: 0;
    }
  }

  .keyboard {
    margin-top: auto;
    width: var(--keyb-width);
    max-width: 100%;
    position: relative;

    .presses-instruction {
      font-size: var(--fz-h5);
      font-weight: 500;
      margin-bottom: var(--s-md);
      .key {
        display: inline-block;
        background-color: var(--c-primary);
        color: var(--c-text-on-primary);
        border-radius: var(--br-sm);
        padding: 0 0.25em;
      }
    }

    &.noFormat {
      :deep(.key.backspace),
      :deep(.key.iso-enter),
      :deep(.key.enter),
      :deep(.key.right-shift),
      :deep(.key.Backslash) {
        visibility: hidden !important;
      }
    }

    .keyboard-fail-state {
      text-align: center;
      position: absolute;
      color: var(--c-text-secondary);
      top: 34%;
      width: 32em;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
}

.options-wrapper {
  display: flex;
  gap: var(--s-lg);
  margin-top: var(--s-lg);
  align-items: flex-start;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: var(--s-md);

  .option {
    display: flex;
    gap: var(--s-sm);
    align-items: center;
    justify-content: center;
    border: 1px solid var(--c-divider);
    border-radius: var(--br-lg);
    padding: var(--s-lg) var(--s-xl);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: var(--c-text-primary);

    &:hover {
      background-color: var(--c-secondary-hover);
    }

    &.active {
      background-color: var(--c-secondary-active);
      --primary-outline: var(--c-text-primary);
      --secondary-outline: var(--c-text-tertiary);
    }

    .icon {
      --size: 20px;
      width: var(--size);
      height: var(--size);
    }
    &.format {
      gap: calc(var(--s-lg) - 3px);
      padding: var(--s-sm) var(--s-lg) var(--s-sm) calc(var(--s-sm) - 3px);
      font-weight: 500;

      .icon {
        --size: 60px;
        .enter-border {
          stroke: var(--primary-outline, var(--c-text-secondary));
        }
        .enter-icon {
          fill: var(--primary-outline, var(--c-text-secondary));
        }
        .secondary {
          stroke: var(--secondary-outline, var(--c-secondary-border));
        }
      }
    }

    .label {
      font-weight: 400;
    }
  }

  &.languages {
    gap: var(--s-sm);
  }
  .language {
    padding: var(--s-sm) var(--s-md);
    display: inline-block;
    border-radius: var(--br-md);
    border: 1px solid var(--c-divider);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    color: var(--c-text-primary);
    font-weight: 500;

    &:hover,
    &.active {
      background-color: var(--c-secondary-hover);
    }

    .flag {
      position: relative;
      top: 1px;
    }
  }
}
</style>
