// FIRESTORE

import { useUserStore } from '@/stores/userStore'
import type { Dayjs } from 'dayjs'
import { addDoc, collection, doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore'
import { debounce } from 'lodash-es'
import { app } from './firebase'

const db = getFirestore(app)

export const fetchUserData = async (email: string) => {
  const docRef = doc(db, 'users', email)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    return null
  }
}

export const setUserData = async (email: string, data: any) => {
  const docRef = doc(db, 'users', email)
  await setDoc(docRef, data)
}

// NOTE: should be called ONLY after the initial object creation
export const updateUserData = async (email: string, data: any) => {
  const docRef = doc(db, 'users', email)
  await updateDoc(docRef, data)
  // await updateDoc(docRef, flattenObjectToDotNotation(data, keysToNotFlatten))
}

export const debouncedUpdateUserData = debounce(updateUserData, 500, { leading: true })

export type Feedback = {
  rating: number
  age: string
  occupation: string
  good: string
  bad: string
  videoSession: boolean
  contact?: string
  currentLocale: string
}

export const addFeedback = async (feedback: Feedback) => {
  const userStore = useUserStore()
  if (!userStore.authData) {
    // prevent Firestore spam from unauthorized users
    return
  }

  await addDoc(collection(db, 'feedback'), {
    email: userStore.authData.email,
    feedback: {
      ...feedback,
      contact: feedback.contact ?? '',
    },
    createdAt: new Date(),
  })
}

// SIMPLE FEEDBACK

export type SimpleFeedback = {
  message: string
  contact?: string
}

export const addSimpleFeedback = async (feedback: SimpleFeedback) => {
  const userStore = useUserStore()
  if (!userStore.authData) {
    // prevent Firestore spam from unauthorized users
    return
  }

  await addDoc(collection(db, 'feedback'), {
    email: userStore.authData.email,
    feedback: {
      ...feedback,
      contact: feedback.contact ?? '',
    },
    createdAt: new Date(),
  })
}

// UTILS

type InputObject = Record<string, any>
type OutputObject = Record<string, string>

// function flattenObjectToDotNotation(obj: InputObject, keysToNotFlatten: string[] = [], parentKey = '', result: OutputObject = {}): OutputObject {
//   Object.keys(obj).forEach((key) => {
//     const newKey = parentKey ? `${parentKey}.${key}` : key

//     if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key]) && !keysToNotFlatten.includes(newKey)) {
//       flattenObjectToDotNotation(obj[key], keysToNotFlatten, newKey, result)
//     } else {
//       result[newKey] = obj[key]
//     }
//   })
//   return result
// }

// NOTE: must not include any dots (dot notation in Firestore will break structure)
export const dateIndex = (date: Dayjs) => {
  return date.format('DD-MM-YYYY-HH:mm:ss:SSS')
}
