<script setup lang="ts">
import { navigateToLanding } from '@/plugins/i18n'
import { useUserStore } from '@/stores/userStore'
import { useI18n } from 'vue-i18n'
import Button from './Button.vue'

const userStore = useUserStore()
const { t } = useI18n()

const goHome = () => {
  navigateToLanding(userStore.settings.locale)
}
</script>

<template>
  <div class="not-found-view">
    <div class="inner">
      <svg class="illustration" width="765" height="393" viewBox="0 0 765 393" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g stroke="var(--c-surface)">
          <path
            d="M184.986 1C180.733 85.6383 145.855 254.915 40.3706 254.915C-91.4847 254.915 294.866 223.707 314.006 95.3315C329.318 -7.36926 163.955 244.985 79.3602 384"
            stroke-width="24.7"
          />
          <path
            d="M621.986 3C617.733 87.6383 582.855 256.915 477.371 256.915C345.515 256.915 731.866 225.707 751.006 97.3315C766.318 -5.36926 600.955 246.985 516.36 386"
            stroke-width="24.7"
          />
          <path
            d="M429.837 234.779C520.375 91.4707 578.024 -89.817 393.716 96.198C154.877 337.249 259.633 504.186 429.837 234.779Z"
            stroke-width="24.7"
          />
        </g>
      </svg>

      <div class="content">
        <h1 class="title">{{ t('pageNotFound') }}</h1>
        <Button size="lg" @click="goHome">{{ t('goHome') }}</Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.not-found-view {
  position: fixed;
  inset: 0;
  z-index: 10;
  background-color: var(--c-background);
  padding: var(--s-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--s-lg);
}

.inner {
  position: relative;
  width: 765px;
  height: 393px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .illustration {
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: -1;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--s-lg);
    position: relative;
    &:before {
      content: url('');
      position: absolute;
      border-radius: 100%;
      background: radial-gradient(circle, rgb(var(--c-surface-rgb) / 1), rgb(var(--c-surface-rgb) / 0) 70%);
      width: 500px;
      height: 500px;
      z-index: -1;
    }
  }

  .title {
    font-size: var(--fz-h1);
    max-width: 400px;
    text-align: center;
    font-weight: 500;
  }

  :deep(.btn) {
    background: var(--c-background);
    border-color: var(--c-divider);
    color: var(--c-text-primary);
    &:hover,
    &:focus {
      background: var(--c-surface) !important;
    }
  }
}
</style>
