<script lang="ts" setup>
import Button from '@/components/Button.vue'
import { getNativeLangTitle, navigateToLanding, UserLocale } from '@/plugins/i18n'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { locale } = useI18n()

const dropdown = ref(null)
const isDropdownOpened = ref(false)
const route = useRoute()

const onLocaleChange = (to: UserLocale) => {
  isDropdownOpened.value = false
  setTimeout(() => navigateToLanding(to), 100)
}

onClickOutside(dropdown, (event) => (isDropdownOpened.value = false))
</script>

<template>
  <div class="dropdown" ref="dropdown" :class="{ landing: route.name === 'landing' }">
    <Button variant="outlined" @click="isDropdownOpened = !isDropdownOpened">
      <span class="text">{{ getNativeLangTitle(locale) }}</span>
      <svg class="icon arrow-down" :class="{ up: isDropdownOpened }">
        <use href="#icon-arrow"></use>
      </svg>
    </Button>
    <transition name="dropdown-dialog">
      <ul v-show="isDropdownOpened" class="dialog">
        <li class="dialog-item" v-for="lc in Object.values(UserLocale)" :key="lc" :class="{ active: locale === lc }" @click="onLocaleChange(lc)">
          <div class="text primary">{{ getNativeLangTitle(lc) }}</div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .arrow-down {
    margin-left: var(--s-sm);
    position: relative;
    transition: all 0.2s ease;
    color: var(--c-secondary-icon);
    width: 0.875rem;
    height: 0.875rem;
    &.up {
      transform: rotate(180deg);
    }
  }

  .dialog {
    --vertical-spacing: 6px;
    list-style: none;
    margin: 0;
    padding: var(--vertical-spacing) 0;
    background-color: var(--c-background);
    border: 1px solid var(--c-secondary-border);
    border-radius: var(--br-sm);
    position: absolute;
    right: 0;
    top: calc(100% + 0.25rem);
    width: auto;
    min-width: 150px;
    box-shadow: var(--box-shadow-default);
    font-weight: normal;
    z-index: 999;

    .dialog-item {
      padding: var(--vertical-spacing) 1rem;

      .text.primary {
        color: var(--c-text-primary);
        font-size: var(--fz-sm);

        &.danger {
          color: var(--c-text-danger);
        }
      }

      &.title {
        padding: var(--vertical-spacing) 1rem var(--s-xs);
        color: var(--c-text-tertiary);
        font-size: var(--fz-xs);
        font-weight: 500;
      }

      &:not(.title) {
        &:hover,
        &.active {
          background-color: var(--c-secondary-hover);
        }
        &:hover {
          cursor: pointer;
        }
        &.active {
          cursor: default !important;
        }
      }
    }

    .divider {
      width: 100%;
      height: var(--vertical-spacing);
    }
  }
}

.dropdown-dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dropdown-dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dropdown-dialog-enter-from,
.dropdown-dialog-leave-to {
  transform: translateY(-0.625rem);
  transform: scale(0.8);
  opacity: 0;
}
</style>
