<script setup lang="ts">
import AccuracyColorsBreakdown from '@/components/AccuracyColorsBreakdown.vue'
import Button from '@/components/Button.vue'
import HighlightViewbox from '@/components/HighlightViewbox.vue'
import { shortcutsConfig } from '@/configs/shortcuts-config'
import { buildCourse } from '@/course/course-builder'
import { getChapterTitle, getLessonTitle } from '@/course/course-lesson-titles'
import { Chapter } from '@/course/course-types'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { formatDuration } from '@/helpers/format-utils'
import { Char } from '@/helpers/keyboards/KeyChar'
import { Layer } from '@/helpers/keyboards/Layer'
import { toFixed } from '@/helpers/main-utils'
import { accuracyColor } from '@/helpers/metric-color-scales'
import { localizedDayFormat, localizedDayjs } from '@/plugins/dayjs'
import router from '@/router'
import { useAppStore } from '@/stores/appStore'
import { useCourseStore } from '@/stores/courseStore'
import { useTrainingStore } from '@/stores/trainingStore'
import { useUserStore } from '@/stores/userStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { Accuracy, Metric } from '@/types/metric-types'
import { CharTypingResult } from '@/types/typing-result/CharTypingResult'
import JSConfetti from 'js-confetti'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const jsConfetti = new JSConfetti()

// to debug highlight viewport
const debug = false
const DAILY_GOAL_SEC = 15 * 60

const { t } = useI18n()
const courseStore = useCourseStore()
const userStore = useUserStore()
const trainingStore = useTrainingStore()
const appStore = useAppStore()

const course = buildCourse(courseStore.current.layout)!
const completionStats = computed(() => courseStore.current.stats.completion)

// guarded in router
const result = computed(() => trainingStore.lastTypingResult!)
const lastTypingResult = computed(() => CharTypingResult.fromFullTypingResult(result.value, courseStore.current.layout.os))

const currLesson = computed(() => result.value.lessonCoords)
const currLessonMetadata = computed(() => course.content[currLesson.value.chapter][currLesson.value.index])
const currLessonTitle = computed(() => getLessonTitle(currLessonMetadata.value))
const currLessonChapterTitle = computed(() => getChapterTitle(currLesson.value.chapter))
const currLessonChapterIndex = computed(() => Object.keys(course.content).indexOf(currLesson.value.chapter))
const currChapterLessonsCount = computed(() => course.content[currLesson.value.chapter].length)

const nextLesson = computed(() => completionStats.value.nextLesson())
const nextLessonMetadata = computed(() => course.content[nextLesson.value.chapter][nextLesson.value.index])
const nextLessonTitle = computed(() => getLessonTitle(nextLessonMetadata.value))

const layout = computed(() => courseStore.current.layout)

const focusLayer = computed(() => {
  if (currLesson.value.chapter === Chapter.Shift) {
    return Layer.Shift
  }
  if (currLesson.value.chapter === Chapter.Punctuation) {
    const char = currLessonMetadata.value.metadata.char[0]
    const charLayer = layout.value.getKeysToType(new Char(char))?.[0].layer
    return charLayer ?? Layer.Default
  }
  if (currLesson.value.chapter === Chapter.Numbers) {
    const numLayer = layout.value.getKeysToType(new Char('1'))?.[0].layer
    return numLayer ?? Layer.Default
  }

  return Layer.Default
})

const metricsRatio = ref<'default' | 'wide'>('default')

// today time
const todayStats = computed(() => courseStore.current.stats.today)
const metrics = computed(() => todayStats.value.metrics())
const timeCurrent = computed(() => {
  return toFixed((lastTypingResult.value.typingTimeMs / (DAILY_GOAL_SEC * 1000)) * 100, 2)
})

const confettiDurationSec = '2s'
const timeCurrentPct = ref('0%')
const congratsOpacity = ref(1)
const hideCongratsDescription = ref(false)
const fullAccuracy = new Accuracy(1)
onMounted(() => {
  nextTick(() => {
    timeCurrentPct.value = `${timeCurrent.value}%`
    if (lastTypingResult.value.accuracy.percentage === 100) {
      jsConfetti.addConfetti({
        confettiColors: ['#2BC83E', '#e4f4dd', '#0f8bff', '#83c2ff'],
      })
      congratsOpacity.value = 0
      setTimeout(() => (hideCongratsDescription.value = true), parseInt(confettiDurationSec) * 1000)
    }
  })
})

const timeCompleted = computed(() => {
  const totalTimeToday = toFixed((metrics.value.typingTimeMs / (DAILY_GOAL_SEC * 1000)) * 100, 2)
  return Math.min(totalTimeToday - timeCurrent.value, 100)
})
const timeCompletedPct = computed(() => `${timeCompleted.value}%`)

// shortcuts

const shortcuts = {
  continue: new KeyboardShortcut(shortcutsConfig.continue, () => {
    userStore.currentLesson = nextLesson.value

    router.push({ name: 'typing' })
  }),
  restart: new KeyboardShortcut(shortcutsConfig.restart, () => {
    router.push({ name: 'typing' })
  }),
}

useKeyboardShortcuts(Object.values(shortcuts))
</script>

<template>
  <div
    class="full-accuracy-congrats"
    :style="{ opacity: congratsOpacity }"
    v-show="lastTypingResult.accuracy.percentage === 100 && !hideCongratsDescription"
  >
    {{ t('accuracy') }} {{ fullAccuracy.format({ precision: 0 }) }}
  </div>
  <div class="view-wrapper">
    <div class="main">
      <div class="main-view">
        <div class="title">{{ t('Result.trainingResult') }}</div>
        <div class="subtitle">
          <span>{{ t('Result.lessonN', currLesson.index + 1) }}</span
          >&nbsp;<span>{{ currLessonTitle }}</span>
        </div>

        <div class="metrics" :class="[metricsRatio]">
          <div class="metric" :class="{ debug }">
            <div class="number">
              <div class="subtitle">{{ t('accuracy') }}</div>
              <div class="value-wrapper">
                <div class="value">{{ lastTypingResult.accuracy.format({ appendUnit: false }) }}</div>
                <div class="unit">%</div>

                <div class="accuracy-indicator" :style="{ '--color': accuracyColor(lastTypingResult.accuracy.percentage) }">
                  <tippy theme="ts info" placement="top" class="tippy">
                    <template #content>
                      <AccuracyColorsBreakdown style="font-size: var(--fz-xs)" :value="lastTypingResult.accuracy.percentage" />
                    </template>
                  </tippy>
                </div>
              </div>
            </div>
            <HighlightViewbox @update-ratio="metricsRatio = $event" :layer="focusLayer" :metric="Metric.Accuracy" :debug="debug" />
          </div>

          <div class="metric speed">
            <div class="number">
              <div class="subtitle">{{ t('speed') }}</div>
              <div class="value-wrapper">
                <span class="value">{{ lastTypingResult.speed.format({ appendUnit: false, unit: userStore.settings.speedUnit }) }}</span
                >&nbsp;<span class="unit">{{ t(userStore.settings.speedUnit) }}</span>
              </div>
            </div>
            <HighlightViewbox :layer="focusLayer" :metric="Metric.Speed" />
          </div>
        </div>
      </div>
    </div>
    <div class="aside">
      <div class="chapter-progress">
        <div class="title">{{ t('Result.chapterN', currLessonChapterIndex + 1) }} {{ currLessonChapterTitle }}</div>
        <div class="lessons">
          <div
            v-for="lessonNumber in currChapterLessonsCount"
            class="lesson"
            :class="{ completed: lessonNumber - 1 < currLesson.index, current: lessonNumber - 1 === currLesson.index }"
          >
            {{ lessonNumber }}
          </div>
        </div>
        <div class="subtitle">{{ t('Result.completedNFromMLessons', [currLesson.index + 1, currChapterLessonsCount]) }}</div>
      </div>

      <div class="today-time">
        <div class="header">
          <div class="title">
            <svg><use href="#icon-calendar"></use></svg>
            {{ localizedDayjs().format(localizedDayFormat()) }}
          </div>
          <div class="subtitle">{{ t('Result.typingTime') }}</div>
        </div>
        <div class="progress">
          <div class="text">
            <i18n-t keypath="Result.todayTypingTimeProgressLabel" v-if="timeCompleted !== 100">
              <span>{{ formatDuration(metrics.typingTimeMs / 1000) }}</span>
              <span>{{ formatDuration(DAILY_GOAL_SEC, { lastUnitCanBeZero: false }) }}</span>
            </i18n-t>
            <i18n-t keypath="Result.todayTypingTimeProgressLabelCompleted" v-else>
              <span>{{ formatDuration(metrics.typingTimeMs / 1000) }}</span>
            </i18n-t>
          </div>
          <div class="progress-bar">
            <div
              class="inner completed"
              :style="{ flex: `0 0 ${timeCompletedPct}` }"
              v-tippy="
                timeCompleted !== 100
                  ? t('Result.prevLessonsTime', [formatDuration((metrics.typingTimeMs - lastTypingResult.typingTimeMs) / 1000)])
                  : null
              "
            ></div>
            <div
              class="inner current"
              :style="{ flex: `0 0 ${timeCurrentPct}` }"
              v-tippy="t('Result.currentLessonTime', [formatDuration(lastTypingResult.typingTimeMs / 1000)])"
            ></div>
          </div>
        </div>
      </div>

      <div class="next">
        <div class="info">
          <div class="subtitle">{{ t('Result.nextLesson') }}</div>
          <div class="title">{{ t('Result.lessonN', nextLesson.index + 1) }} {{ nextLessonTitle }}</div>
        </div>
        <Button style="width: 100%" shortcut="Enter" size="md" @click="shortcuts.continue.action">
          {{ t('continue') }}
        </Button>
        <Button variant="outlined" style="width: 100%" :shortcut="shortcuts.restart.print()" size="md" @click="shortcuts.restart.action">
          {{ t('repeat') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.full-accuracy-congrats {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(0 0 0 / 0.9);
  display: grid;
  place-items: center;
  font-size: var(--fz-h1);
  color: #fff;
  font: 600;
  z-index: 999;
  opacity: 1;
  transition: all v-bind(confettiDurationSec) ease-in-out;
}

.view-wrapper {
  --content-height: calc(576px - var(--header-height) - var(--grid-cell) * 3);
  --gap: calc(var(--grid-cell) * 2);
  flex: 1;
  display: flex;
  width: auto;
  margin: auto;
  padding-top: var(--grid-cell);
  padding-bottom: var(--grid-cell);
}

.main {
  background: radial-gradient(50% 50% at 100% 50%, var(--c-surface) 0%, var(--c-background) 100%);
  display: flex;
  position: relative;
  flex: 1;

  &:before {
    content: url('');
    position: absolute;
    width: 1px;
    height: 100%;
    right: -1px;
    top: 0;
    z-index: 1;
    background-image: linear-gradient(180deg, var(--c-background) 0%, var(--c-divider) 50%, var(--c-background) 100%);
  }
}

.main-view {
  height: var(--content-height);
  margin: auto;
  padding-right: var(--gap);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    color: var(--c-text-primary);
    font-size: var(--fz-h3);
    font-weight: 500;
  }

  & > .subtitle {
    margin-top: var(--s-sm);
  }

  .metrics {
    flex: 1;
    margin-top: var(--s-xxl);
    display: flex;
    gap: var(--s-lg);
    width: 100%;

    .metric {
      flex: 1;
      border-radius: var(--br-xl);
      border: 1px solid var(--c-divider);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      overflow: hidden;
      background-color: var(--c-surface);

      .number {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .value-wrapper {
        display: flex;
        font-size: var(--fz-h1);
        margin-top: var(--s-sm);
        .unit {
          opacity: 0.3;
        }
      }

      .accuracy-indicator {
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        position: absolute;
        right: 8px;
        top: 8px;
        background-color: var(--color);

        .tippy {
          display: inline-block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.debug {
        overflow: visible;
      }
    }

    &.wide {
      flex-direction: column;
      .metric {
        flex: 1;
        .number {
          flex-direction: row;
          gap: var(--s-md);
          .value-wrapper {
            margin: 0;
          }
        }
      }
    }
  }
}

.aside {
  font-size: var(--fz-md);
  font-weight: 500;
  padding-left: var(--gap);
  height: var(--content-height);
  margin-top: auto;
  margin-bottom: auto;
  max-width: 340px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chapter-progress {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 500;
    }

    .lessons {
      display: flex;
      gap: var(--s-xs);
      margin: var(--s-md) 0 var(--s-sm);
      flex-wrap: wrap;

      .lesson {
        display: inline-grid;
        place-items: center;
        height: 24px;
        width: 24px;
        border-radius: var(--br-sm);
        background: var(--c-divider);
        font-size: var(--fz-sm);
        font-weight: 500;
        &.completed {
          background: var(--c-primary-subdued-border);
          color: var(--c-text-on-primary);
        }
        &.current {
          background: var(--c-primary);
          color: var(--c-text-on-primary);
        }
      }
    }

    .subtitle {
      color: var(--c-text-secondary);
      font-weight: 500;
      font-size: var(--fz-sm);
    }
  }

  .today-time {
    border: 1px solid var(--c-divider);
    border-radius: var(--br-xl);
    padding: var(--s-md);
    gap: var(--s-lg);
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    .header {
      display: flex;
      gap: var(--s-sm);
      .title {
        display: flex;
        align-items: baseline;
        gap: 0.3em;
        font-weight: 500;
        font-size: var(--fz-md);
        svg {
          width: 15px;
          height: 13px;
        }
      }
      .subtitle {
        color: var(--c-text-tertiary);
        font-weight: 500;
      }
    }

    .progress {
      .text {
        font-size: var(--fz-sm);
        color: var(--c-text-secondary);
        font-weight: 500;
        span {
          color: var(--c-text-primary);
        }
      }
      .progress-bar {
        margin-top: var(--s-sm);
        height: 6px;
        border-radius: 6px;
        background-color: var(--c-divider);
        display: flex;
        overflow: hidden;
        .inner {
          height: 100%;
          background-color: var(--c-primary);
          transition: all 1s ease-in-out;
          &.completed {
            background-color: var(--c-primary-subdued-border);
          }
        }
      }
    }
  }

  .next {
    display: flex;
    flex-direction: column;
    gap: var(--s-md);

    .info {
      .subtitle {
        color: var(--c-text-secondary);
        font-size: var(--fz-sm);
        font-weight: 500;
      }
      .title {
        font-weight: 500;
        margin-top: var(--s-xs);
        margin-bottom: var(--s-sm);
      }
    }
  }
}
</style>
