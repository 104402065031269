<script setup lang="ts">
import Button from '@/components/Button.vue'
import CountryFlag from '@/components/CountryFlag.vue'
import DarkLightImage from '@/components/DarkLightImage.vue'
import { buildCourse } from '@/course/course-builder'
import { getChapterTitle, getLessonTitle } from '@/course/course-lesson-titles'
import { logAnalyticsEvent, logPageViewEvent } from '@/helpers/analytics'
import { KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { fillSpacesWithNbsp } from '@/helpers/main-utils'
import { getUserLanguage } from '@/helpers/user-agent-utils'
import type { LanguageCode } from '@/languages/languages-config'
import { signInWithGoogle } from '@/plugins/firebase/auth'
import { explicitUserLocales, switchAppLocale, UserLocale } from '@/plugins/i18n'
import { Theme, useAppStore } from '@/stores/appStore'
import { useCookieStore } from '@/stores/cookieStore'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { Metric } from '@/types/metric-types'
import LanguageMenu from '@/views/landing/LanguageMenu.vue'
import { onClickOutside, useWindowSize } from '@vueuse/core'
import dayjs from 'dayjs'
import { computed, onMounted, onUnmounted, ref, useTemplateRef, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import FeatureCard from './FeatureCard.vue'
import FeatureCardBig from './FeatureCardBig.vue'
import { previewLayoutDefinition } from './highlights-data'
import KeyboardHighlightPreview from './KeyboardHighlightPreview.vue'
import TypingPreview from './TypingPreview.vue'

const { width } = useWindowSize()
const MIN_VIEW_WIDTH_TO_CONTINUE = 576

const router = useRouter()
const route = useRoute()
const appStore = useAppStore()
const cookieStore = useCookieStore()
const userStore = useUserStore()
const courseStore = useCourseStore()

const { t, locale } = useI18n()

const localeParam = route.params.locale as string
if (localeParam !== '' && !explicitUserLocales.includes(localeParam as UserLocale)) {
  router.replace('/')
}
const routeLocale = (localeParam || UserLocale.en) as UserLocale
switchAppLocale(routeLocale)

watchEffect(() => {
  if (cookieStore.isAnalyticsAllowed) {
    logPageViewEvent()
  }
})

onMounted(() => {
  appStore.theme = Theme.Dark
  document.querySelector('body')?.classList.add('landing')
})

onUnmounted(() => {
  document.querySelector('body')?.classList.remove('landing')
})

// handle first visit, browser and route locale could mismatch but we prefer the one from route
userStore.waitForInit(() => {
  userStore.saveSettings({ locale: routeLocale })
  switchAppLocale(routeLocale)
})

const userAuthorized = computed(() => !!userStore.authData)
const userLayoutConfigured = computed(() => !!courseStore.courses.length)
const continueToHome = computed(() => userAuthorized.value || userLayoutConfigured.value)

// card 1: language
const possibleTypingLanguage = computed(() => getUserLanguage())

const getLangCodeNotDuplicated = (langCode: LanguageCode) => {
  if (possibleTypingLanguage.value === langCode) {
    return 'en'
  }
  return langCode
}

const currentMetric = ref<null | Metric>(null)

const currentMetricColor = computed(() =>
  currentMetric.value === Metric.Accuracy ? '#5FC752' : currentMetric.value === Metric.Speed ? 'rgb(0 117 235)' : 'rgb(132 163 190)',
)

const userLayout = ref(KeyboardLayout.fromLayoutDefinition(previewLayoutDefinition(locale.value as UserLocale)))
watch(locale, (to) => {
  userLayout.value = KeyboardLayout.fromLayoutDefinition(previewLayoutDefinition(to as UserLocale))
})
const course = computed(() => buildCourse(userLayout.value)!)

const scrollToSmoothly = (targetId?: string) => {
  menuExpanded.value = false
  showMobilePlaceholder.value = false

  if (!targetId) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    return
  }

  const element = document.getElementById(targetId)
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
}

// actions handling

const getStarted = (fromTag: 'nav' | 'hero' | 'pricing') => {
  if (width.value < MIN_VIEW_WIDTH_TO_CONTINUE) {
    showMobilePlaceholder.value = true
    return
  }
  logAnalyticsEvent('click_get_started', { from: fromTag, continue: continueToHome.value })
  window.location.assign(continueToHome.value ? '/home' : '/setup')
}

const login = () => {
  menuExpanded.value = false
  if (width.value < MIN_VIEW_WIDTH_TO_CONTINUE) {
    showMobilePlaceholder.value = true
    return
  }
  signInWithGoogle()
}

const openCorporateAccountForm = () => {
  logAnalyticsEvent('click_business_edition_form', {})
  window.open('https://tally.so/r/w5qadb', '_blank')
}

// nav
const navRef = useTemplateRef('nav')
const menuExpanded = ref(false)
onClickOutside(navRef, (event) => (menuExpanded.value = false))

// mobile placeholder
const showMobilePlaceholder = ref(false)
const copied = ref(false)
const copiedTimeout = ref<ReturnType<typeof setTimeout> | undefined>(undefined)
const copyLink = () => {
  navigator.clipboard.writeText('https://typing.school')

  copied.value = true
  clearTimeout(copiedTimeout.value)
  copiedTimeout.value = setTimeout(() => (copied.value = false), 3000)
}

const continueBtnText = computed(() => {
  return continueToHome.value ? t('continue') : t('Landing.heroAction')
})
</script>

<template>
  <div
    class="container"
    :style="{
      '--current-metric-color': currentMetricColor,
    }"
  >
    <nav class="nav" ref="nav">
      <RouterLink tabindex="-1" to="/" class="logo" @click.prevent="scrollToSmoothly()">
        <img src="/src/assets/img/logo-keyboard.png" />
        <DarkLightImage class="logo" path="logo-text" ext="svg" alt="Typing School" />
      </RouterLink>

      <div class="expand-menu-btn" @click="menuExpanded = !menuExpanded">
        <i class="fi fi-br-menu-burger"></i>
      </div>

      <div class="nav-content" :class="{ shown: menuExpanded }">
        <div class="quick-links">
          <a href="#why-touch-typing" class="link" @click.prevent="scrollToSmoothly('why-touch-typing')">{{ t('Landing.NavLinks.about') }}</a>
          <a href="#features" class="link" @click.prevent="scrollToSmoothly('features')">{{ t('Landing.NavLinks.features') }}</a>
          <a href="#pricing" class="link" @click.prevent="scrollToSmoothly('pricing')">{{ t('Landing.NavLinks.pricing') }}</a>
        </div>

        <div class="nav-actions">
          <LanguageMenu class="language-btn" />
          <template v-if="!userAuthorized">
            <Button variant="outlined" class="action-btn" @click="login">{{ t('Landing.NavActions.login') }}</Button>
            <Button class="action-btn" @click="getStarted('nav')">{{ continueBtnText }}</Button>
          </template>
        </div>
      </div>
    </nav>

    <header class="hero">
      <h1 class="title" v-html="t('Landing.heroTitle')"></h1>
      <div class="hero-actions">
        <Button class="action-button" @click="getStarted('hero')" size="lg">
          <svg class="icon">
            <use href="#ts-favicon"></use>
          </svg>
          <span>{{ continueBtnText }}</span>
        </Button>
        <div class="action-subtitle" v-if="!continueToHome">{{ t('Landing.withoutRegistration') }}</div>
      </div>
    </header>

    <section class="features">
      <FeatureCard class="feature-card">
        <p class="feature-title">
          {{ t('Landing.firstFeatureTitle') }}
        </p>
        <div class="illustration">
          <div class="flag it"><CountryFlag type="separate" :language-code="getLangCodeNotDuplicated('it')" /></div>
          <div class="flag es"><CountryFlag type="separate" :language-code="getLangCodeNotDuplicated('es')" /></div>
          <div class="flag en"><CountryFlag type="separate" :language-code="possibleTypingLanguage" /></div>
          <div class="flag ru"><CountryFlag type="separate" :language-code="getLangCodeNotDuplicated('fr')" /></div>
          <div class="flag uk"><CountryFlag type="separate" :language-code="getLangCodeNotDuplicated('uk')" /></div>
          <div class="flag fr"><CountryFlag type="separate" :language-code="getLangCodeNotDuplicated('ru')" /></div>
          <div class="flag de"><CountryFlag type="separate" :language-code="getLangCodeNotDuplicated('pl')" /></div>
        </div>
      </FeatureCard>
      <FeatureCard class="feature-card">
        <p class="feature-title">{{ t('Landing.secondFeatureTitle') }}</p>
        <div class="illustration keyboard">
          <img src="/src/assets/img/keyboard-glassy.png" />
        </div>
      </FeatureCard>
      <FeatureCard class="feature-card">
        <p class="feature-title">{{ t('Landing.thirdFeatureTitle') }}</p>
        <div class="illustration statistics">
          <img src="/src/assets/img/charts-illustration.svg" />
        </div>
      </FeatureCard>
    </section>

    <section id="why-touch-typing" class="section about-touch-typing">
      <h2 class="section-title">{{ t('Landing.AboutSection.title') }}</h2>
      <div class="wrapper">
        <div class="text">
          <p v-html="t('Landing.AboutSection.description')"></p>
        </div>
        <div class="illustration">
          <img src="/src/assets/img/about-touch-typing-illustration.png" />
        </div>
      </div>
    </section>

    <div id="features" class="section why-typing-school">
      <h2 class="section-title">{{ t('Landing.FeaturesSection.title') }}</h2>
      <div class="app-highlights">
        <FeatureCardBig glow-color="#8790A1" class="app-highlight">
          <div class="keyboard-wrap">
            <TypingPreview />
          </div>
        </FeatureCardBig>
        <FeatureCardBig glow-color="var(--current-metric-color)" class="app-highlight insights">
          <h2 class="app-highlight-title">{{ t('Landing.FeaturesSection.thirdTitle') }}</h2>
          <p class="app-highlight-subtitle">
            <span class="accuracy" :class="{ current: currentMetric === Metric.Accuracy }">{{ t('accuracyLower') }}</span
            >&nbsp;•&nbsp;<span class="speed" :class="{ current: currentMetric === Metric.Speed }">{{ t('speedLower') }}</span>
          </p>
          <div class="keyboard-wrap">
            <KeyboardHighlightPreview v-model:metric="currentMetric" />
          </div>
        </FeatureCardBig>
        <FeatureCardBig glow-color="#8f8ddb" class="app-highlight about-course">
          <h2 class="app-highlight-title">{{ t('Landing.FeaturesSection.firstTitle') }}</h2>
          <p class="app-highlight-subtitle"></p>

          <div class="course-preview">
            <div v-for="(lessons, chapter, index) in course.content" class="chapter">
              <div v-if="index < 7">
                <div class="chapter-title">
                  <div class="title-text" v-html="fillSpacesWithNbsp(`${index + 1}. ${getChapterTitle(chapter)}`)"></div>
                </div>
                <div class="lesson-title" v-for="(lesson, i) in lessons">
                  <div v-html="fillSpacesWithNbsp(getLessonTitle(lesson))"></div>
                </div>
              </div>
            </div>
          </div>
        </FeatureCardBig>
      </div>
    </div>

    <div id="pricing" class="section pricing">
      <h2 class="section-title">{{ t('Landing.PricingSection.title') }}</h2>

      <div class="pricing-options">
        <div class="pricing-option">
          <div class="info">
            <div class="header">
              <svg class="icon">
                <use href="#ts-favicon"></use>
              </svg>
              <div class="option-title">{{ t('Landing.PricingSection.Options.Standard.title') }}</div>
            </div>
            <div class="description">{{ t('Landing.PricingSection.Options.Standard.description') }}</div>
            <Button class="action-btn" size="md" @click="getStarted('pricing')">{{ continueBtnText }}</Button>
          </div>
          <div class="price">
            <div class="value">{{ t('Landing.PricingSection.Options.Standard.price') }}</div>
            <div class="subtitle">{{ t('Landing.PricingSection.Options.Standard.priceSubtitle') }}</div>
          </div>
        </div>
        <div class="pricing-option corporate">
          <div class="info">
            <div class="header">
              <svg class="icon">
                <use href="#ts-favicon"></use>
              </svg>
              <div class="option-title">{{ t('Landing.PricingSection.Options.Corporate.title') }}</div>
            </div>
            <div class="description">{{ t('Landing.PricingSection.Options.Corporate.description') }}</div>
            <Button variant="outlined" class="action-btn" size="md" @click="openCorporateAccountForm">{{
              t('Landing.PricingSection.Options.Corporate.actionButton')
            }}</Button>
          </div>
          <div class="price">
            <div class="subtitle">{{ t('Landing.PricingSection.Options.Corporate.priceSubtitle') }}</div>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="footer-copyright">Typing School, Inc. © {{ dayjs().format('YYYY') }}</div>
      <div class="footer-nav">
        <a href="/privacy" target="_blank" class="link">{{ t('Landing.FooterLinks.privacy') }}</a>
        <a href="/terms" target="_blank" class="link">{{ t('Landing.FooterLinks.terms') }}</a>
        <a href="mailto:hello@typing.school" target="_blank" class="link">{{ t('Landing.FooterLinks.contact') }}</a>
      </div>
    </footer>

    <div v-show="showMobilePlaceholder" class="mobile-placeholder">
      <div class="illustration">
        <img src="/src/assets/img/keyboard-glassy.png" />
      </div>
      <p class="title">{{ t('Landing.MobilePlaceholder.title') }}</p>
      <div class="link">https://<span class="highlight">typing.school</span></div>
      <Button variant="primary" :disabled="copied" size="lg" @click="copyLink">{{
        copied ? t('Landing.MobilePlaceholder.CopyButton.copied') : t('Landing.MobilePlaceholder.CopyButton.default')
      }}</Button>
      <Button variant="outlined" size="lg" @click="showMobilePlaceholder = false">{{ t('Landing.MobilePlaceholder.closeButton') }}</Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:global(body.landing) {
  // sizes
  --nav-x-margins: 16px;
  --max-inner-container-width: 1170px;
  --max-container-with-nav-width: calc(var(--max-inner-container-width) + var(--nav-x-margins) * 2);
  // font size
  --fz-hero-title: 88px;
  --fz-hero-subtitle: 32px;
  --fz-heading-1: 48px;
  --fz-heading-2: 36px;
  --fz-body: 16px;
  --fz-body-sm: 14px;
  --fz-body-lg: 21px;
  --fz-action: 16px;
  --fz-action-sm: 14px;
  --fz-action-lg: 18px;
  // colors
  --c-background: #0f0f10;
  --c-text-primary: #ffffff;
  --c-text-secondary: rgb(255 255 255 / 0.6);
  --c-divider: rgb(255 255 255 / 0.1);
  --c-secondary-icon: rgb(255 255 255 / 0.2);
  --c-secondary-hover: rgba(255, 255, 255, 0.1);

  background: var(--c-background);
  min-height: auto;
  width: 100%;
  overflow-x: hidden;

  @media (max-width: 768px) {
    --nav-x-margins: 8px;
    // font size
    --fz-hero-title: 48px;
    --fz-hero-subtitle: 22px;
    --fz-heading-1: 36px;
    --fz-heading-2: 24px;
    --fz-body-lg: 18px;
  }
}

.container {
  max-width: var(--max-inner-container-width);
  padding: 0 calc(var(--nav-x-margins) * 2);
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  * {
    box-sizing: border-box;
    font-family: 'RF Dewi';
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  :deep(.dropdown) {
    .toggle {
      border-color: rgba(255, 255, 255, 0.1);
      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
    .dialog {
      border-color: rgba(255, 255, 255, 0.1);
      background: rgb(19 19 20 / 0.75);
      backdrop-filter: blur(12px);
      -webkit-backdrop-filter: blur(12px);
    }
    * {
      font-weight: 600;
    }
  }
  :deep(.btn) {
    font-weight: 600;
    font-size: var(--fz-body);
    &.sm {
      font-size: var(--fz-body-sm);
    }
    &.lg {
      font-size: var(--fz-body-lg);
    }
    &.secondary {
      border-color: rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.1);
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }
}

.nav {
  display: flex;
  align-items: center;
  gap: 2rem;
  border-radius: 16px;
  position: fixed;
  left: 0;
  right: 0;
  top: var(--nav-x-margins);
  margin: 0 auto;
  width: calc(100% - var(--nav-x-margins) * 2);
  max-width: var(--max-container-with-nav-width);
  padding: 8px 8px 8px 16px;
  z-index: 20;

  &:after {
    content: url('');
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgb(19 19 20 / 0.75);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    z-index: -1;
    border-radius: 16px;
  }

  .logo {
    font-size: 0;
    img {
      height: 2rem;
    }
  }
  .nav-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
  .quick-links {
    display: flex;
    gap: 24px;
    position: relative;
    bottom: 1px;
  }
  .nav-actions {
    display: flex;
    gap: 8px;

    .language-btn :deep(button) {
      border-radius: 8px;
    }

    .action-btn {
      border-radius: 8px;
    }
  }
  .expand-menu-btn {
    display: none;
  }

  @media (max-width: 960px) {
    flex-wrap: wrap;
    gap: 1rem;
    .expand-menu-btn {
      margin-left: auto;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    .nav-content {
      flex: 0 0 100%;
      display: none;
      &.shown {
        display: flex;
      }
    }
    .quick-links {
      gap: 16px;
    }
  }

  @media (max-width: 768px) {
    .nav-content {
      align-items: flex-start;
    }
    .quick-links {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      a {
        height: 36px;
        line-height: 36px;
        color: var(--c-text-primary);
        font-size: var(--fz-body-lg);
        font-weight: 600;
      }
    }
    .nav-actions {
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.5rem;
    }
  }
}

.hero {
  text-align: left;
  position: relative;
  padding-top: 180px;

  .title {
    font-size: var(--fz-hero-title);
    letter-spacing: -0.02em;
    font-weight: 600;
    max-width: 900px;
    hyphens: manual;
  }

  .hero-actions {
    margin-top: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 72px;
    gap: 21px;

    .action-button {
      border-radius: 10px !important;
      :deep(.content-wrapper) {
        display: flex;
        align-items: center !important;
        gap: 12px;
      }
      :deep(.icon) {
        width: 32px;
        height: 32px;
        --custom-text-color: var(--c-primary);
      }
    }

    .action-subtitle {
      font-size: var(--fz-body);
      font-weight: 400;
      color: var(--c-text-secondary);
    }
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: -250px;
    right: 0;
    width: 728px;
    height: 728px;
    opacity: 12%;
    background: radial-gradient(circle closest-side, #0f8bff 0%, rgba(15, 139, 255, 0) 100%);
    backdrop-filter: blur(70px);
  }

  @media (max-width: 768px) {
    padding-top: 120px;
    .hero-actions {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }
  }
}

.features {
  margin-top: 4rem;
  display: flex;
  gap: var(--s-md);
  width: 100%;

  .feature-card {
    :deep(.content) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .feature-title {
      font-size: var(--fz-body-lg);
      margin: 16px 22px 0;
      max-width: 282px;
    }
    .illustration {
      height: 186px;
      position: relative;

      .flag {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        :deep(.flag) {
          display: block;
          width: 100%;
          height: 100%;
        }
        :deep(img) {
          width: 100%;
          height: 100%;
          transform: scale(1.4);
        }
        &.it {
          right: 200px;
          bottom: -38px;
          width: 103px;
          height: 103px;
        }
        &.es {
          right: 221px;
          bottom: 79px;
        }
        &.en {
          right: 100px;
          bottom: 31px;
          width: 108px;
          height: 108px;
        }
        &.ru {
          right: 128px;
          bottom: -45px;
          width: 65px;
          height: 65px;
        }
        &.uk {
          right: 91px;
          bottom: 14px;
          width: 25px;
          height: 25px;
        }
        &.fr {
          right: 51px;
          bottom: 39px;
          width: 40px;
          height: 40px;
        }
        &.de {
          right: 44px;
          bottom: 97px;
          width: 55px;
          height: 55px;
        }
      }

      &.keyboard {
        img {
          position: absolute;
          top: 5rem;
          max-width: unset;
          right: 40px;
          height: 200px;
          transform: rotate(-7deg);
        }
      }

      &.statistics {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 1rem;
        img {
          height: calc(186px - 2rem);
        }
      }

      &:after {
        content: url('');
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      &:after {
        background: linear-gradient(to bottom, rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 0.45) 100%);
        z-index: 10;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.section {
  padding-top: 6rem;

  .section-title {
    font-size: var(--fz-heading-1);
    max-width: 760px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    padding-top: 3rem;
  }
}

.section.about-touch-typing {
  .wrapper {
    display: flex;
    gap: 2rem;
    justify-content: center;
  }
  .illustration {
    width: 200px;
    order: 0;
  }
  .text {
    order: 1;
    p {
      font-size: var(--fz-body-lg);
      max-width: 25em;
      margin-bottom: 16px;
      color: var(--c-text-secondary);
      :deep(mark) {
        background: none;
        color: var(--c-text-primary);
      }
    }
  }

  @media (min-width: 769px) {
    padding-top: 8rem;
    margin-bottom: 2rem;

    .section-title {
      max-width: unset;
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
    .illustration {
      order: 1;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .text {
      p {
        max-width: unset;
      }
    }
    img {
      width: 150px;
      max-width: 100%;
    }
  }
}

.section.why-typing-school {
  .app-highlights {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .app-highlight {
    --spacing-between-keyboard: 5rem;
    --padding-y: 5rem;
    --padding-x: 5rem;

    .keyboard-wrap {
      max-width: 864px;
      margin: 0 auto;
    }

    .app-highlight-title {
      text-align: center;
      font-size: var(--fz-heading-2);
      font-weight: 600;
    }

    .app-highlight-subtitle {
      color: var(--c-text-primary);
      text-align: center;
      margin-top: 1rem;
      margin-bottom: var(--spacing-between-keyboard);
      font-size: var(--fz-body-lg);
      font-weight: 400;
      max-width: unset;
    }

    &.about-course {
      :deep(.glow) {
        z-index: 11;
      }

      .course-preview {
        display: flex;
        gap: 2rem;
        max-height: 250px;

        .chapter-title {
          font-weight: 600;
          margin-bottom: 8px;
          font-size: var(--fz-body);
        }
        .lesson-title {
          color: var(--c-text-secondary);
          font-size: var(--fz-body);
        }
      }

      &:after,
      &:before {
        content: url('');
        display: block;
        width: 100%;
        height: calc(var(--padding-y) + 260px);

        bottom: 0;
        right: 0;
        position: absolute;
      }
      &:after {
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #131314 100%);
        z-index: 10;
      }
      &:before {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #131314 100%);
        z-index: 11;
      }

      @media (max-width: 768px) {
        .course-preview {
          .chapter-title,
          .lesson-title {
            font-size: var(--fz-body-sm);
          }
        }

        &:after,
        &:before {
          height: calc(var(--padding-y) + 250px);
        }
      }
    }

    &.insights {
      .app-highlight-subtitle span {
        padding: 0 4px;
        margin: 0 2px;
        &.current {
          display: inline-block;
          border-radius: 4px;
          background-color: var(--current-metric-color);
          &.accuracy {
            color: #000;
          }
          &.speed {
            color: #fff;
          }
        }
      }
    }

    @media (max-width: 960px) {
      --spacing-between-keyboard: 7vw;
      --padding-y: 7vw;
      --padding-x: 7vw;
    }
  }
}

.section.pricing {
  .pricing-options {
    display: flex;
    gap: 2rem;
  }

  .pricing-option {
    display: flex;
    flex: 1;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #131314;
    padding: 8px;
    gap: 1rem;

    .info {
      flex: 1;
      padding: 16px;
      min-height: 240px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .header {
        display: flex;
        gap: 8px;

        .icon {
          width: 36px;
          height: 36px;
          color: var(--c-primary);
          --custom-text-color: var(--c-text-on-primary);
        }

        .option-title {
          background-color: #1f1f20;
          border-radius: 18px;
          min-height: 36px;
          padding: 8px 16px;
          display: grid;
          place-items: center;
        }
      }

      .description {
        margin: 1rem 0 2rem;
        color: var(--c-text-secondary);
        font-size: var(--fz-lg);
        max-width: 360px;
        .highlight {
          color: var(--c-text-primary);
          font-weight: 600;
        }
      }

      :deep(.action-btn) {
        margin-top: auto;
        border-radius: 8px;
      }
    }

    &.corporate .header .icon {
      color: #1f1f20;
      --custom-text-color: #df3f3a;
    }

    .price {
      flex: 0 0 180px;
      background-color: #1f1f20;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      text-align: center;
      padding: 1rem;

      .value {
        font-size: 28px;
        font-weight: 600;
      }

      .subtitle {
        color: var(--c-text-secondary);
        font-size: var(--fz-body-sm);
      }
    }
  }

  @media (max-width: 960px) {
    .pricing-options {
      flex-direction: column;
      gap: 1rem;
    }
    .info {
      min-height: unset !important;
    }
  }

  @media (max-width: 576px) {
    .pricing-option {
      flex-direction: column;
    }
    .price {
      flex: 0 0 120px !important;
      order: -1;
    }
  }
}

footer {
  margin-top: 6rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--c-divider);
  padding: 1rem 0 1.2rem;

  .footer-copyright {
    font-size: var(--fz-body);
    font-weight: 400;
    text-align: left;
  }

  .footer-nav {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }

  @media (max-width: 960px) {
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    .footer-nav {
      gap: 0;
      justify-content: center;
      .link {
        margin: 4px 8px;
      }
    }
  }
}

.link {
  font-size: var(--fz-body);
  font-weight: 400;
  color: var(--c-text-secondary);

  &:hover {
    color: var(--text-primary);
    transition: color 0.3s;
  }
}

.mobile-placeholder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: var(--c-background);
  padding: 32px 16px 16px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  gap: 1rem;

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    top: -250px;
    right: 0;
    width: 728px;
    height: 728px;
    opacity: 12%;
    background: radial-gradient(circle closest-side, #0f8bff 0%, rgba(15, 139, 255, 0) 100%);
    backdrop-filter: blur(70px);
  }

  .illustration {
    --illustration-height: 200px;
    height: var(--illustration-height);
    position: relative;
    margin-bottom: 8rem;

    img {
      height: var(--illustration-height);
      max-width: unset;
      position: absolute;
      top: 0;
      right: 25vw;
      transform: rotate(10deg);
    }

    // width: auto;
    // max-width: unset;
    // width: 800px;

    // width: auto;
    // position: absolute;
    // top: 200px;
    // right: 200px;
  }

  .title {
    font-size: var(--fz-body-lg);
    // font-weight: 600;
    // text-align: center;
  }

  .link {
    font-size: var(--fz-heading-2);
    color: var(--c-text-tertiary);
    margin: 1rem 0 1.6rem;
    text-align: center;
    .highlight {
      font-weight: 600;
      color: var(--c-text-primary);
    }
  }
}
</style>
