<script setup lang="ts">
import KeyboardHighlight from '@/components/KeyboardHighlight.vue'
import KeyboardHighlightLegend from '@/components/KeyboardHighlightLegend.vue'
import { Metric } from '@/types/metric-types'
import { ref } from 'vue'

type Props = {
  title: string
  subtitle: string
  metric: Metric
}
const props = defineProps<Props>()

// layouts

const currentRangeValues = ref(null)
const currentRangeColors = ref(null)
</script>

<template>
  <div class="wrapper">
    <div class="surface">
      <div class="keyboard-layout">
        <div class="header">
          <div class="info">
            <div class="title">{{ title }}</div>
            <div class="description">{{ subtitle }}</div>
          </div>

          <KeyboardHighlightLegend
            v-if="currentRangeValues && currentRangeColors"
            :metric="metric"
            :rangeValues="currentRangeValues"
            :rangeColors="currentRangeColors"
          />
        </div>

        <KeyboardHighlight :metric="metric" v-model:rangeValues="currentRangeValues" v-model:rangeColors="currentRangeColors" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--s-md);
}

.surface {
  border-radius: var(--br-xl);
  display: flex;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .keyboard-layout {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: var(--fz-sm);
      margin-bottom: var(--s-md);

      .title {
        color: var(--c-text-primary);
        font-size: var(--fz-lg);
        font-weight: 500;
      }

      .description {
        color: var(--c-text-secondary);
        font-size: var(--fz-sm);
      }

      span {
        color: var(--c-text-secondary);
      }

      .toggle-wrapper {
        display: flex;
        align-items: center;
        gap: var(--s-sm);
      }
    }
  }
}
</style>
