// export const OperationalSystems = {
//   mac: 'mac',
//   win: 'win',
//   lin: 'lnx',
// } as const

// export type OS = keyof typeof OperationalSystems

export enum OS {
  win = 'win',
  mac = 'mac',
  lnx = 'lnx',
}

export const osTitles: Record<OS, string> = {
  [OS.mac]: 'Mac',
  [OS.win]: 'Windows',
  [OS.lnx]: 'Linux',
}

export const allOS: OS[] = [OS.mac, OS.win, OS.lnx]
