<script lang="ts" setup>
import type { LanguageCode } from '@/languages/languages-config'
import { getLangTitle } from '@/plugins/i18n'
import { onClickOutside } from '@vueuse/core'
import { useTemplateRef } from 'vue'
import Button from './Button.vue'
import CountryFlag from './CountryFlag.vue'

type Props = {
  languages: LanguageCode[]
}
defineProps<Props>()

const value = defineModel<LanguageCode | null>('selected', { default: null })

const dropdownRef = useTemplateRef('dropdown')

const isDropdownOpened = defineModel<boolean>({ default: false })

onClickOutside(dropdownRef, () => {
  isDropdownOpened.value = false
})

const onItemClick = (langCode: LanguageCode) => {
  value.value = langCode
  isDropdownOpened.value = false
}
</script>

<template>
  <div class="dropdown" ref="dropdown">
    <Button variant="outlined" size="md" @click="isDropdownOpened = !isDropdownOpened">
      <template v-if="!value">
        <span class="text">{{ $t('select') }}</span>
      </template>
      <template v-else>
        <span><CountryFlag :language-code="value" type="separate" style="position: relative; top: 2px; margin-right: var(--s-sm)" /></span>
        <span class="no-text-selection">{{ getLangTitle(value) }}</span>
      </template>

      <svg class="icon arrow-down" :class="{ up: isDropdownOpened }">
        <use href="#icon-arrow"></use>
      </svg>
    </Button>
    <transition name="dropdown-dialog">
      <ul v-show="isDropdownOpened" class="dialog">
        <li v-for="lang in languages" :key="lang" :value="lang" @click="onItemClick(lang)" class="dialog-item" :class="{ active: value === lang }">
          <span><CountryFlag :language-code="lang" style="position: relative; top: 1px" /></span>
          <div class="text">{{ getLangTitle(lang) }}</div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10;

  .arrow-down {
    margin-left: var(--s-sm);
    position: relative;
    transition: all 0.2s ease;
    color: var(--c-secondary-icon);
    width: 0.875rem;
    height: 0.875rem;
    &.up {
      transform: rotate(180deg);
    }
  }

  .dialog {
    --vertical-spacing: 6px;
    --arrow-right-width: calc(0.75rem + 2 * var(--s-md));
    --sm-icon-size: 0.75rem;

    list-style: none;
    margin: 0;
    padding: var(--vertical-spacing) 0;
    background-color: var(--c-background);
    border: 1px solid var(--c-secondary-border);
    border-radius: var(--br-md);
    position: absolute;
    left: 0;
    top: calc(100% + 0.25rem);
    width: auto;
    white-space: nowrap;
    box-shadow: var(--box-shadow-default);
    font-weight: normal;
    max-height: 310px;
    overflow-y: auto;

    &.nested {
      top: calc(-1px - var(--vertical-spacing));
      right: calc(100% + 0.25rem);

      .dialog-item {
        // equal empty space for left and right sides
        padding-right: calc(var(--s-md) + var(--sm-icon-size) + var(--gap));

        .text {
          margin-right: 0;
        }
      }
    }

    .dialog-item {
      --gap: var(--s-sm);
      position: relative;
      display: flex;
      gap: var(--gap);
      align-items: center;
      padding: var(--vertical-spacing) var(--s-md);
      color: var(--c-text-primary);
      font-size: var(--fz-sm);
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      &.danger {
        color: var(--c-text-danger);
      }

      &:hover,
      &.open,
      &.active {
        background-color: var(--c-secondary-hover);
      }

      .text {
        margin-right: var(--s-md);

        .description {
          // margin-right: calc(-1 * calc(var(--s-md) + var(--sm-icon-size) + var(--gap)));
          font-size: var(--fz-xs);
          color: var(--c-text-secondary);
          font-weight: normal;

          &.wide {
            width: 200px;
            white-space: wrap;
          }
        }
      }

      .toggle {
        margin-left: auto;
      }

      .icon {
        &.prepend {
          width: 1rem;
          height: 1rem;
        }

        &.check {
          width: var(--sm-icon-size);
          height: var(--sm-icon-size);
          visibility: hidden;
        }

        &.arrow-right {
          width: var(--sm-icon-size);
          height: var(--sm-icon-size);
          margin-left: auto;
          color: var(--c-secondary-icon);
          rotate: 270deg;
        }
      }
      &.active .icon.check {
        visibility: visible;
      }

      &.title {
        padding: var(--vertical-spacing) var(--s-md);
        padding-top: var(--s-md);
        color: var(--c-text-tertiary);
        font-size: var(--fz-xs);
        &:hover {
          background: none;
          cursor: default !important;
        }
      }
    }

    .divider {
      width: 100%;
      height: var(--vertical-spacing);
    }
  }
}

.dropdown-dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dropdown-dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dropdown-dialog-enter-from,
.dropdown-dialog-leave-to {
  transform: translateY(-0.625rem);
  transform: scale(0.8);
  opacity: 0;
}
</style>
