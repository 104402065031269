<script setup lang="ts">
import { KeyboardFormat } from '@/helpers/keyboards/KeyboardLayout'
import { Layer } from '@/helpers/keyboards/Layer'
import { useCourseStore } from '@/stores/courseStore'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import type { Metric } from '@/types/metric-types'
import { computed, ref, watchEffect } from 'vue'
import KeyboardHighlight from './KeyboardHighlight.vue'

type Props = {
  metric: Metric
  layer?: Layer
  debug?: boolean
}
const props = withDefaults(defineProps<Props>(), { layer: Layer.Default })
const emit = defineEmits(['updateRatio'])

const courseStore = useCourseStore()
const layout = computed(() => courseStore.current.layout)

const currentRangeValues = ref(null)
const currentRangeColors = ref(null)

const participatedKeys = computed(() => {
  return courseStore.current.stats.lastTraining.perKeycode(props.metric)
})

// viewbox real size
enum Ratio {
  Default = 'Default',
  Wide = 'Wide',
}
const padding = 12
const paddingPx = padding + 'px'

const viewboxWidthVariants = {
  [Ratio.Default]: 282.5 - padding * 2,
  [Ratio.Wide]: 593 - padding * 2,
} as const

const viewboxHeightVariants = {
  [Ratio.Default]: 160 - padding * 2,
  [Ratio.Wide]: 60 - padding * 2,
} as const

const ratioVariant = ref<Ratio>(Ratio.Default)

const viewboxWidth = computed(() => viewboxWidthVariants[ratioVariant.value])
const viewboxHeight = computed(() => viewboxHeightVariants[ratioVariant.value])

const viewboxWidthPx = computed(() => viewboxWidth.value + 'px')
const viewboxHeightPx = computed(() => viewboxHeight.value + 'px')

// sizes in units (1 unit = 1 key)
const gap = 0.1667
const keybWidth = 16.835
const keybHeight = 5 + 4 * gap // 5.6668
// shift for each keyboard row to find real position of key
const leftIndexAdjustments = computed(() => {
  return [0, 1.667 + gap, 1.889 + gap, layout.value.format === KeyboardFormat.ANSI ? 2.5 + gap : 1.3333 + gap]
})

const boundingRect = computed(() => {
  let [minLeft, maxLeft, minTop, maxTop] = [999, -1, 999, -1]

  const keyCodes = participatedKeys.value
    .keys()
    .map((v) => LayeredKeyCode.parse(v))
    .filter((k) => k.layer === props.layer)
    .map((k) => k.keyCode)

  const rows = courseStore.current.layout.keyRows
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i].map((k) => k.code)
    const firstIndex = row.findIndex((k) => keyCodes.includes(k))
    const lastIndex = row.findLastIndex((k) => keyCodes.includes(k))

    if (firstIndex !== -1 || lastIndex !== -1) {
      const firstIndexUnits = firstIndex * (1 + gap) + leftIndexAdjustments.value[i]
      const lastIndexUnits = lastIndex * (1 + gap) + leftIndexAdjustments.value[i]
      const iUnits = i * (1 + gap)

      minLeft = firstIndexUnits < minLeft ? firstIndexUnits : minLeft
      maxLeft = lastIndexUnits > maxLeft ? lastIndexUnits : maxLeft

      minTop = iUnits < minTop ? iUnits : minTop
      maxTop = iUnits > maxTop ? iUnits : maxTop
    }
  }

  const coords = {
    topLeft: [minTop, minLeft],
    bottomRight: [maxTop, maxLeft],
  }

  const width = coords.bottomRight[1] - coords.topLeft[1] + 1
  const height = coords.bottomRight[0] - coords.topLeft[0] + 1
  const ratio = width / height

  return {
    ...coords,
    width,
    height,
    ratio,
  }
})

const keybTopShift = computed(() => `-${(boundingRect.value.topLeft[0] / keybHeight) * 100}%`)
const keybLeftShift = computed(() => `-${(boundingRect.value.topLeft[1] / keybWidth) * 100}%`)
const transform = computed(() => `translate(${keybLeftShift.value}, ${keybTopShift.value})`)

const calcUnitAsPx = (viewBoxW: number, viewBoxH: number) => {
  const viewboxRatio = viewBoxW / viewBoxH
  if (boundingRect.value.ratio < viewboxRatio) {
    return viewBoxH / boundingRect.value.height
  } else {
    return viewBoxW / boundingRect.value.width
  }
}

const unitAsPx = computed(() => {
  return calcUnitAsPx(viewboxWidth.value, viewboxHeight.value)
})

watchEffect(() => {
  const defaultUnitSize = calcUnitAsPx(viewboxWidthVariants[Ratio.Default], viewboxHeightVariants[Ratio.Default])
  const wideUnitSize = calcUnitAsPx(viewboxWidthVariants[Ratio.Wide], viewboxHeightVariants[Ratio.Wide])
  if (wideUnitSize > defaultUnitSize) {
    ratioVariant.value = Ratio.Wide
    emit('updateRatio', 'wide')
  }
})

const freeVerticalSpace = computed(() => {
  const heightPx = unitAsPx.value * boundingRect.value.height
  return viewboxHeight.value - heightPx
})
const topShiftPx = computed(() => (freeVerticalSpace.value > 0 ? `${freeVerticalSpace.value / 2}px` : '0'))

const freeHorizontalSpace = computed(() => {
  const widthPx = unitAsPx.value * boundingRect.value.width
  return viewboxWidth.value - widthPx
})
const leftShiftPx = computed(() => (freeHorizontalSpace.value > 0 ? `${freeHorizontalSpace.value / 2}px` : '0'))

const keyboardWidth = computed(() => {
  const width = keybWidth * unitAsPx.value
  return `${width}px`
})

const keyboardHeight = computed(() => {
  const height = Math.round(parseInt(keyboardWidth.value) * (keybHeight / keybWidth))
  return `${height}px`
})
</script>

<template>
  <div ref="viewbox" class="highlight-wrapper" :class="{ debug }">
    <div class="highlight-outer">
      <div class="highlight-inner">
        <KeyboardHighlight
          :handle-presses="false"
          :layer="layer"
          :metric="metric"
          :last-training="true"
          v-model:rangeValues="currentRangeValues"
          v-model:rangeColors="currentRangeColors"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.highlight-wrapper {
  margin-top: auto;
  width: calc(v-bind(viewboxWidthPx) + v-bind(paddingPx) * 2);
  height: calc(v-bind(viewboxHeightPx) + v-bind(paddingPx) * 2);
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    pointer-events: none;
    box-shadow: inset 0 0 5px 5px var(--c-surface);
  }
}

.highlight-outer {
  width: 100%;
  height: 100%;
  position: relative;
  padding: v-bind(paddingPx);

  .highlight-inner {
    width: v-bind(keyboardWidth);
    height: v-bind(keyboardHeight);
    position: absolute;
    top: calc(v-bind(topShiftPx) + v-bind(paddingPx));
    left: calc(v-bind(leftShiftPx) + v-bind(paddingPx));
    transform: v-bind(transform);

    :deep(.key) {
      --background-color: transparent;
      --c-keyboard-key-text: var(--c-text-tertiary);
    }
  }
}

.highlight-wrapper.debug {
  overflow: visible;
  border: 1px solid red;
  &:before {
    display: none;
  }

  .highlight-outer {
    transform: unset;
  }
}
</style>
